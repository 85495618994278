import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

const ChatMessenger: React.FC = () => {
    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center vh-100"
            style={{
                backgroundImage: 'linear-gradient(to bottom right, #89cfff, #2a4659)',
                color: '#fff',
                textAlign: 'center',
            }}
        >
            <h1 className="display-4 mb-3">Feature Coming Soon</h1>
            <p className="fs-5">
                We are working hard to bring this feature to you. Stay tuned for updates!
            </p>
        </div>
    );
};

export default ChatMessenger;
