import './App.css';
import MainBody from './Components/MainBody';

import {
  BrowserRouter as Router,
} from "react-router-dom";


function App() {
  return (
    <div className="App">

      <Router>
        <MainBody />
      </Router>

    </div>
  );
}

export default App;
