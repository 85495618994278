import React, { useState, useEffect, useRef } from 'react';
import CreateUserForm from './CreateUserForm.tsx';
import { Button, Modal, Table, FormControl, InputGroup, Spinner, Form, Col, Row } from 'react-bootstrap';
import { HashLoader } from 'react-spinners';

interface Agent {
  id: number;
  email: string;
  username: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  address?: string;
  contact: string;
  role: number;
  isDeleted: boolean;
  online: boolean;
  validated: boolean;
  active: boolean;
  country: string;
  subscriptionType?: number;
  subscription?: any;
}

const Roles = {
  ADMIN: 0,
  AGENT: 2,
  USER: 3,
  Specialist: 1,
};

const AgentManagement: React.FC = () => {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [isAgentModalOpen, setIsAgentModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalAgents, setTotalAgents] = useState(0); // Assuming API returns total number of agents
  const [hasMore, setHasMore] = useState(true); // To track if more data is available
  const [searchTerm, setSearchTerm] = useState('');
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const pageSize = 20;
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState(''); // 'success' or 'error'
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchAgents = async () => {
      if (!hasMore) return; // Prevent fetching if no more data
      setLoading(true);

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/User/get-all-agents?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAgents((prevAgents) => [...prevAgents, ...data]);
          setTotalAgents(data.totalCount); // Update total agents from API
          setHasMore(data.length === pageSize); // Check if there are more agents to load
        } else {
          console.error('Failed to fetch agents');
        }
      } catch (error) {
        console.error('Error fetching agents:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, [pageNumber]);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 100 && hasMore && !loading) {
        setPageNumber((prevPage) => prevPage + 1);
      }
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleViewClick = (agent: Agent) => {
    setSelectedAgent(agent);
    setIsAgentModalOpen(true);
  };

  const handleEditClick = () => {
    if (selectedAgent) {
      setIsAgentModalOpen(false);
      setIsEditModalOpen(true);
    }
  };

  const handleCancel = () => {
    setIsEditModalOpen(false);
    setIsAgentModalOpen(true);
  };
  const filteredAgents = agents.filter((agent) =>
    agent.firstName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleResponse = (response) => {
    if (response.ok) {
      setResponseStatus('success');
      setResponseMessage('Changes have been saved successfully!');
    } else {
      setResponseStatus('error');
      setResponseMessage('There was an error saving your changes. Please try again.');
    }
    setIsResponseModalOpen(true);
  };
  const handleCloseResponseModal = () => {
    setIsResponseModalOpen(false);
    setIsEditModalOpen(false); // Close the edit modal
  };
  

const handleConfirmSave = () => {
  setIsConfirmDialogOpen(true);
};

const handleConfirmedSave = () => {
  setSaving(true); // Start showing the spinner
  if (selectedAgent) {
    const payload = {
      id: selectedAgent.id,
      firstName: selectedAgent.firstName,
      middleName: selectedAgent.middleName || '',
      lastName: selectedAgent.lastName,
      address: selectedAgent.address || '',
      contact: selectedAgent.contact,
      country: selectedAgent.country || '',
      role: selectedAgent.role,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/User/update-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
      body: JSON.stringify(payload),
    })
    .then((response) => {
      handleResponse(response);
    })
    .catch((error) => {
      setResponseStatus('error');
      setResponseMessage('There was an error saving your changes. Please try again.');
      setIsResponseModalOpen(true);
    })
    .finally(() => {
      setSaving(false); // Hide the spinner
    });
  }
  setIsConfirmDialogOpen(false);
};


  return (
    <>
      {loading && pageNumber === 1 ? (
        <div className="d-flex mb-5 justify-content-center align-items-center" style={{ height: '100vh' }}>
          <HashLoader color="#007bff" size={60} />
        </div>
      ) : (
        <div className="container mt-4">
          <div className="mb-4 d-flex justify-content-around align-items-center">
            <InputGroup className="w-50">
              <FormControl
                placeholder="Search agents"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              {searchTerm && (
                <InputGroup.Text>
                  <i className="bi bi-x-circle" onClick={handleClearSearch}></i>
                </InputGroup.Text>
              )}
            </InputGroup>
            <Button variant="primary" className="fs-6" onClick={() => setIsFormModalOpen(true)}>
              Create User
            </Button>
          </div>

          <div ref={scrollContainerRef} onScroll={handleScroll} style={{ maxHeight: '75vh', overflowY: 'auto' }}>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Role</th>
                  <th>Online</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredAgents.map((agent) => (
                  <tr key={agent.id}>
                    <td>{agent.id}</td>
                    <td>{agent.firstName} {agent.middleName} {agent.lastName}</td>
                    <td>{agent.contact}</td>
                    <td>{getRoleName(agent.role)}</td>
                    <td>{agent.online ? 'Yes' : 'No'}</td>
                    <td>{agent.active ? 'Active' : 'Inactive'}</td>
                    <td>
                      <Button variant="info"  onClick={() => handleViewClick(agent)} className="me-2">View</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            {loading && <Spinner animation="border" className="d-block mx-auto" />}
            </Table>

        </div>
          
      {selectedAgent && (
        <Modal show={isAgentModalOpen} onHide={() => setIsAgentModalOpen(false)} centered backdrop="static"   keyboard={false}>
          <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>

            <Modal.Title>Employee Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>ID:</strong> {selectedAgent.id}</p>
            <p><strong>First Name:</strong> {selectedAgent.firstName}</p>
            <p><strong>Last Name:</strong> {selectedAgent.lastName}</p>
            <p><strong>Email:</strong> {selectedAgent.email}</p>
            <p><strong>Contact:</strong> {selectedAgent.contact}</p>
            <p><strong>Role:</strong> {getRoleName(selectedAgent.role)}</p>
            <p><strong>Address:</strong> {selectedAgent.address || 'N/A'}</p>
            <p><strong>Address:</strong> {selectedAgent.country }</p>
            <p><strong>Status:</strong> {selectedAgent.active ? 'Active' : 'Inactive'}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setIsAgentModalOpen(false)}>
              Close
            </Button>
            <Button variant="success" onClick={handleEditClick}>
              Edit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
  
      {/* Create User Modal */}
      <Modal show={isFormModalOpen} onHide={() => setIsFormModalOpen(false)} centered backdrop="static"   keyboard={false}>
        <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>

          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateUserForm setAgent={setAgents}/>
        </Modal.Body>
      </Modal>
  
      {/* Edit User Modal */}
      {selectedAgent && (
  <Modal show={isEditModalOpen} onHide={() => setIsEditModalOpen(false)} centered backdrop="static"   keyboard={false}>
    <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>
      <Modal.Title>Edit User</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p><strong>ID:</strong> {selectedAgent.id}</p>
      <p><strong>Username:</strong> {selectedAgent.username}</p>
      
      <Form>
        <Row>
          <Col md={4}>
            <Form.Group controlId="formFirstName">
              <Form.Label><strong>First Name:</strong></Form.Label>
              <Form.Control
                type="text"
                value={selectedAgent.firstName}
                onChange={(e) => setSelectedAgent(prev => prev ? { ...prev, firstName: e.target.value } : prev)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="formMiddleName">
              <Form.Label><strong>Middle Name:</strong></Form.Label>
              <Form.Control
                type="text"
                value={selectedAgent.middleName}
                onChange={(e) => setSelectedAgent(prev => prev ? { ...prev, middleName: e.target.value } : prev)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="formLastName">
              <Form.Label><strong>Last Name:</strong></Form.Label>
              <Form.Control
                type="text"
                value={selectedAgent.lastName}
                onChange={(e) => setSelectedAgent(prev => prev ? { ...prev, lastName: e.target.value } : prev)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label><strong>Email:</strong></Form.Label>
              <Form.Control
                type="text"
                value={selectedAgent.email}
                onChange={(e) => setSelectedAgent(prev => prev ? { ...prev, email: e.target.value } : prev)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formContact">
              <Form.Label><strong>Contact:</strong></Form.Label>
              <Form.Control
                type="text"
                value={selectedAgent.contact}
                onChange={(e) => setSelectedAgent(prev => prev ? { ...prev, contact: e.target.value } : prev)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <Form.Group controlId="formStatus">
              <Form.Label><strong>Status:</strong></Form.Label>
              <Form.Control
                as="select"
                value={selectedAgent.active ? 'active' : 'inactive'}
                onChange={(e) => setSelectedAgent(prev => prev ? { ...prev, active: e.target.value === 'active' } : prev)}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formRole">
              <Form.Label><strong>Role:</strong></Form.Label>
              <Form.Control
                as="select"
                value={selectedAgent.role}
                onChange={(e) => setSelectedAgent(prev => prev ? { ...prev, role: parseInt(e.target.value) } : prev)}
              >
                <option value={Roles.AGENT}>Agent</option>
                <option value={Roles.Specialist}>Specialist</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>
      <Button variant="success" onClick={handleConfirmSave} disabled={saving}>
  {saving ? (
    <span>
      <Spinner as="span" animation="border" size="sm" /> Saving...
    </span>
  ) : (
    'Save Changes'
  )}
</Button>

    </Modal.Footer>
  </Modal>
)}


    {/* Confirmation Dialog */ }
    <Modal show={isConfirmDialogOpen} onHide={() => setIsConfirmDialogOpen(false)} centered backdrop="static"   keyboard={false}>
      <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>
        <Modal.Title>Confirm Save</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to save these changes?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsConfirmDialogOpen(false)}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleConfirmedSave}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  
  {/* Response Modal */ }
<Modal show={isResponseModalOpen} onHide={handleCloseResponseModal} centered  backdrop="static"   keyboard={false}>
  <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>
    <Modal.Title>{responseStatus === 'success' ? 'Success' : 'Error'}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>{responseMessage}</p>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseResponseModal}>
      Close
    </Button>
  </Modal.Footer>
</Modal>



      </div>)}
    </>
  );
};

const getRoleName = (role: number) => {
  switch (role) {
    case Roles.ADMIN:
      return 'Admin';
    case Roles.AGENT:
      return 'Agent';
    case Roles.USER:
      return 'User';
    case Roles.Specialist:
      return 'Specialist';
    default:
      return 'Unknown';
  }
};

export default AgentManagement;
