import React, { useState } from 'react';
import { Navbar, Badge, Breadcrumb, ListGroup, Dropdown, Modal, Button, Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { allVariables } from '../Constants/VariableConstants';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export default function AppNavbar({ title }) {
  const [notifications, setNotifications] = useState([
    'Notification 1',
    'Notification 2',
    'Notification 3',
  ]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleRemoveNotification = (index, e) => {
    e.stopPropagation();
    const newNotifications = notifications.filter((_, i) => i !== index);
    setNotifications(newNotifications);
  };

  const handleUserModalOpen = async () => {
    const userId = localStorage.getItem('userId');
    const userType = parseInt(localStorage.getItem('userType'));
    const token = localStorage.getItem('token');

    if (userType === 0) {
      setShowUserModal(true);
      return;
    }

    try {
      const response = await fetch(`https://phishroko-api.azurewebsites.net/api/User/get-user-details?id=${userId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setUserDetails(data);
      setShowUserModal(true);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleUserModalClose = () => {
    setShowUserModal(false);
  };

  const handleChangePasswordModalOpen = () => {
    setShowChangePasswordModal(true);
  };

  const handleChangePasswordModalClose = () => {
    setShowChangePasswordModal(false);
  };

  const handlePasswordChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };

  const toggleShowPassword = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleChangePasswordSubmit = () => {
    // Handle password change logic here
    console.log('Password change submitted:', passwords);
    handleChangePasswordModalClose();
  };

  return (
    <Navbar expand="md" className="d-flex p-2 bg-body-tertiary w-100">
      <div className="d-flex justify-content-center flex-grow-1">
        <Breadcrumb className="mb-0">
          <Breadcrumb.Item>
            <Navbar.Brand className="text-primary fs-3 fw-bold">
              {title ?? allVariables.dashboardName}
            </Navbar.Brand>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="me-5 text-info" onClick={handleUserModalOpen} style={{ cursor: 'pointer' }}>
        User: {localStorage.getItem('userName')}
      </div>
      <div className="ml-auto d-flex align-items-center me-4">
        <Dropdown align="end">
          <Dropdown.Toggle as="div" className="p-0 position-relative" style={{ cursor: 'pointer' }}>
            <i className="far fa-bell fa-2x"></i>
            {notifications.length > 0 && (
              <Badge pill bg="danger" className="position-absolute top-0 start-100 translate-middle">
                {notifications.length}
              </Badge>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ minWidth: '200px' }}>
            <ListGroup>
              {notifications.map((notification, index) => (
                <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                  {notification}
                  <i
                    className="fas fa-times"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => handleRemoveNotification(index, e)}
                  ></i>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {/* User Details Modal */}
      <Modal show={showUserModal} onHide={handleUserModalClose}>
      <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>
          <Modal.Title>{localStorage.getItem('userType') === '0' ? 'Change Password' : 'User Details'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {localStorage.getItem('userType') !== '0' ? (
            <>
              <p><strong>Username:</strong> {userDetails.username}</p>
              <p><strong>Email:</strong> {userDetails.email}</p>
              <p><strong>First Name:</strong> {userDetails.firstName}</p>
              <p><strong>Last Name:</strong> {userDetails.lastName}</p>
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleChangePasswordModalOpen}>
            Change Password
          </Button>
          <Button variant="secondary" onClick={handleUserModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Change Password Modal */}
      <Modal show={showChangePasswordModal} onHide={handleChangePasswordModalClose}>
      <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCurrentPassword">
              <Form.Label>Current Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword.currentPassword ? "text" : "password"}
                  name="currentPassword"
                  value={passwords.currentPassword}
                  onChange={handlePasswordChange}
                />
                <InputGroup.Text onClick={() => toggleShowPassword('currentPassword')}>
                  {showPassword.currentPassword ? <FaEyeSlash /> : <FaEye />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="formNewPassword" className="mt-3">
              <Form.Label>New Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword.newPassword ? "text" : "password"}
                  name="newPassword"
                  value={passwords.newPassword}
                  onChange={handlePasswordChange}
                />
                <InputGroup.Text onClick={() => toggleShowPassword('newPassword')}>
                  {showPassword.newPassword ? <FaEyeSlash /> : <FaEye />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="formConfirmPassword" className="mt-3">
              <Form.Label>Confirm Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword.confirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={passwords.confirmPassword}
                  onChange={handlePasswordChange}
                />
                <InputGroup.Text onClick={() => toggleShowPassword('confirmPassword')}>
                  {showPassword.confirmPassword ? <FaEyeSlash /> : <FaEye />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleChangePasswordSubmit}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleChangePasswordModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Navbar>
  );
}

AppNavbar.propTypes = { title: PropTypes.string };
