import React, { useEffect, useState, useRef } from 'react';
import { Container,FormControl, Row, Col, Card, Button, Table, Modal, Form, Spinner } from 'react-bootstrap';
import { HashLoader } from 'react-spinners';
import CreateTicketForm from './CreateTicketForm.tsx'; // Adjust the path as necessary
import ViewTicketModal from './ViewTicketModal.tsx'; // Adjust the path as necessary


enum TicketStatus {
    Open = 'Open',
    Assigned = 'Assigned',
    Resolved = 'Resolved',
    Closed = 'Closed'
}



const tableContainerStyle = {
    marginTop: '20px',
    maxHeight: '400px', // Adjust this value as needed
    overflowY: 'auto'
};

const statusStyle = (status: TicketStatus) => {
    let color;
    switch (status) {
        case TicketStatus.Open:
            color = '#007bff'; // Blue
            break;
        case TicketStatus.Assigned:
            color = '#ffc107'; // Yellow
            break;
        case TicketStatus.Resolved:
            color = '#28a745'; // Green
            break;
        case TicketStatus.Closed:
            color = '#6c757d'; // Gray
            break;
        default:
            color = '#e9ecef'; // Light gray
    }
    return {
        color: color,
        fontWeight: 'bold'
    };
};

const statusText = (status: number): TicketStatus => {
    switch (status) {
        case 0:
            return TicketStatus.Open;
        case 1:
            return TicketStatus.Assigned;
        case 2:
            return TicketStatus.Resolved;
        case 3:
            return TicketStatus.Closed;
        default:
            return TicketStatus.Open; // Default fallback
    }
};


export default function TicketManagement() {
    const [records, setRecords] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [totalTickets, setTotalTickets] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [viewModalShow, setViewModalShow] = useState(false);
    const [createTicketModalShow, setCreateTicketModalShow] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState<any | null>(null);
    const [agents, setAgents] = useState<{ id: number, username: string }[]>([]);
    const [filter, setFilter] = useState<'all' | 'open' | 'assigned' | 'resolved' | 'closed'>('all'); // New filter state
    const [selectedFilter, setSelectedFilter] = useState<'all' | 'open' | 'assigned' | 'resolved' | 'closed'>('all');
    const loggedInUserId = parseInt(localStorage.getItem('userId') || '0'); // Assuming userId is stored in localStorage
    const userType = parseInt(localStorage.getItem('userType') || '0'); // Assuming userType is stored in localStorage
    const [pageNumber, setPageNumber] = useState(1);
    const [totalAgents, setTotalAgents] = useState(0); // Assuming API returns total number of agents
    const [hasMore, setHasMore] = useState(true); // To track if more data is available
    const scrollContainerRef = useRef<HTMLDivElement>(null);
  
    const pageSize = 20;
    useEffect(() => {
        const fetchTickets = async () => {
            if (!hasMore) return; // Prevent fetching if no more data
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/ticket/all-tickets?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                });
                if (response.ok) {
                    const result = await response.json();
                    setTotalAgents(result.totalCount); // Update total agents from API
                    setHasMore(result.length === pageSize); 
                    setRecords((prevRec) => [...prevRec,...result]);
                    setTotalTickets(result.length);
                } else {
                    console.error('Failed to fetch tickets:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching tickets:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchAgents = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/User/get-all-agents?page=1&pageSize=100`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setAgents(data.map((agent: any) => ({
                        id: agent.id,
                        username: agent.username
                    })));
                } else {
                    console.error('Failed to fetch agents:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching agents:', error);
            }
        };

        fetchTickets();
        fetchAgents();
    }, [pageNumber]);

    const handleScroll = () => {
        if (scrollContainerRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
          if (scrollTop + clientHeight >= scrollHeight - 100 && hasMore && !loading) {
            setPageNumber((prevPage) => prevPage + 1);
          }
        }
      };
    const handleViewClick = (ticket: any) => {
        setSelectedTicket(ticket);
        setViewModalShow(true);
    };


    const handleCreateTicketClick = () => {
        setCreateTicketModalShow(true);
    };

    const filterTickets = (tickets: any[]) => {
        switch (filter) {
            case 'open':
                return tickets.filter(ticket => ticket.status === 0);
            case 'assigned':
                return tickets.filter(ticket => ticket.status === 1);
            case 'resolved':
                return tickets.filter(ticket => ticket.status === 2);
            case 'closed':
                return tickets.filter(ticket => ticket.status === 3);
            default:
                return tickets;
        }
    };

    // Show exact match filter results
    // const filteredRecords = filterTickets(records).filter((ticket) => {
    //     // Determine if the user is an agent and if the ticket is assigned to them
    //     const isAgent = userType === 2;
    //     const isAssignedToCurrentUser = ticket.assignedTo === loggedInUserId;
    
    //     // Filter logic
    //     const matchesAgentCriteria = !isAgent || isAssignedToCurrentUser;
    //     const matchesSearchTerm = ticket.ticketId?.toString() === searchTerm;
    
    //     return matchesAgentCriteria && matchesSearchTerm;
    // });

    
// Show matching results.
    const filteredRecords = filterTickets(records).filter((ticket) => {
        // Determine if the user is an agent and if the ticket is assigned to them
        const isAgent = userType === 2;
        const isAssignedToCurrentUser = ticket.assignedTo === loggedInUserId;
    
        // Filter logic
        const matchesAgentCriteria = !isAgent || isAssignedToCurrentUser;
        const matchesSearchTerm = ticket.ticketId?.toString().toLowerCase().includes(searchTerm.toLowerCase());
    
        return matchesAgentCriteria && matchesSearchTerm;
    });
    
        // .sort((a, b) => {
        //     if (a.status === 3 && b.status !== 3) return 1; // Move ticket `a` to the bottom if its status is 3
        //     if (a.status !== 3 && b.status === 3) return -1; // Move ticket `b` to the bottom if its status is 3

        //     // Sort by creation time in descending order
        //     return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        // });

    const commonCardStyle = {
        cursor: 'pointer',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        width: '300px', // Set the width for a rectangular shape
        height: '100px', // Set the height for a rectangular shape
        borderRadius: '10px', // Optional: slightly rounded corners
    };

    const getTransformStyle = (selectedFilter, filterType) => {
        return selectedFilter === filterType ? 'translateY(-5px) scale(1.15)' : 'translateY(0) scale(1)';
    };

    const getBoxShadowStyle = (selectedFilter, filterType) => {
        return selectedFilter === filterType
            ? '0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.1)'
            : '0 4px 8px rgba(0, 0, 0, 0.1)';
    };

    const handleMouseOver = (e) => {
        e.currentTarget.style.transform = 'translateY(-10px) scale(1.05)';
        e.currentTarget.style.boxShadow = '0 15px 25px rgba(0, 0, 0, 0.3), 0 10px 10px rgba(0, 0, 0, 0.2)';
    };

    const handleMouseOut = (e, selectedFilter, filterType) => {
        e.currentTarget.style.transform = getTransformStyle(selectedFilter, filterType);
        e.currentTarget.style.boxShadow = getBoxShadowStyle(selectedFilter, filterType);
    };



    return (
        <>
            {loading && pageNumber === 1 ? (
                <div className="d-flex mb-5 justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <HashLoader color="#007bff" size={60} />
                </div>
            ) : (
                <Container className='mt-3'>
                    <Row className='mb-3'>
                        <Col className='d-flex justify-content-around'>

                            <Card
                                className='text-white'
                                style={{
                                    ...commonCardStyle,
                                    background: 'linear-gradient(to bottom right, #007bff, #0056b3)',
                                    transform: getTransformStyle(selectedFilter, 'all'),
                                    boxShadow: getBoxShadowStyle(selectedFilter, 'all'),
                                }}
                                onClick={() => {
                                    setFilter('all');
                                    setSelectedFilter('');
                                }}
                                onMouseOver={handleMouseOver}
                                onMouseOut={(e) => handleMouseOut(e, selectedFilter, 'all')}
                            >

                                <Card.Body className='d-flex flex-column justify-content-center align-items-center text-center' style={{ height: '80%' }}>

                                    <h5>Total Incidents</h5>
                                    <h4 style={{ fontWeight: 'bold' }}>{totalTickets}</h4>
                                </Card.Body>
                            </Card>

                            <Card
                                className='text-white'
                                style={{
                                    ...commonCardStyle,
                                    background: 'linear-gradient(to bottom right, #17a2b8, #117a8b)',
                                    transform: getTransformStyle(selectedFilter, 'open'),
                                    boxShadow: getBoxShadowStyle(selectedFilter, 'open'),
                                }}
                                onClick={() => {
                                    setFilter('open');
                                    setSelectedFilter('open');
                                }}
                                onMouseOver={handleMouseOver}
                                onMouseOut={(e) => handleMouseOut(e, selectedFilter, 'open')}
                            >
                                <Card.Body className='d-flex flex-column justify-content-center align-items-center text-center' style={{ height: '100%' }}>
                                    <h5>Open Incidents</h5>
                                    <h4 style={{ fontWeight: 'bold' }}>{records.filter(ticket => ticket.status === 0).length}</h4>
                                </Card.Body>
                            </Card>

                            <Card
                                className='text-white'
                                style={{
                                    ...commonCardStyle,
                                    background: 'linear-gradient(to bottom right, #ffc107, #e0a800)',
                                    transform: getTransformStyle(selectedFilter, 'assigned'),
                                    boxShadow: getBoxShadowStyle(selectedFilter, 'assigned'),
                                }}
                                onClick={() => {
                                    setFilter('assigned');
                                    setSelectedFilter('assigned');
                                }}
                                onMouseOver={handleMouseOver}
                                onMouseOut={(e) => handleMouseOut(e, selectedFilter, 'assigned')}
                            >
                                <Card.Body className='d-flex flex-column justify-content-center align-items-center text-center' style={{ height: '100%' }}>
                                    <h5>Assigned Incidents</h5>
                                    <h4 style={{ fontWeight: 'bold' }}>{records.filter(ticket => ticket.status === 1).length}</h4>
                                </Card.Body>
                            </Card>

                            <Card
                                className='text-white'
                                style={{
                                    ...commonCardStyle,
                                    background: 'linear-gradient(to bottom right, #28a745, #218838)',
                                    transform: getTransformStyle(selectedFilter, 'resolved'),
                                    boxShadow: getBoxShadowStyle(selectedFilter, 'resolved'),
                                }}
                                onClick={() => {
                                    setFilter('resolved');
                                    setSelectedFilter('resolved');
                                }}
                                onMouseOver={handleMouseOver}
                                onMouseOut={(e) => handleMouseOut(e, selectedFilter, 'resolved')}
                            >
                                <Card.Body className='d-flex flex-column justify-content-center align-items-center text-center' style={{ height: '100%' }}>
                                    <h5>Resolved Incidents</h5>
                                    <h4 style={{ fontWeight: 'bold' }}>{records.filter(ticket => ticket.status === 2).length}</h4>
                                </Card.Body>
                            </Card>

                            <Card
                                className='text-white'
                                style={{
                                    ...commonCardStyle,
                                    background: 'linear-gradient(to bottom right, #6c757d, #5a6268)',
                                    transform: getTransformStyle(selectedFilter, 'closed'),
                                    boxShadow: getBoxShadowStyle(selectedFilter, 'closed'),
                                }}
                                onClick={() => {
                                    setFilter('closed');
                                    setSelectedFilter('closed');
                                }}
                                onMouseOver={handleMouseOver}
                                onMouseOut={(e) => handleMouseOut(e, selectedFilter, 'closed')}
                            >
                                <Card.Body className='d-flex flex-column justify-content-center align-items-center text-center' style={{ height: '100%' }}>
                                    <h5>Closed Incidents</h5>
                                    <h4 style={{ fontWeight: 'bold' }}>{records.filter(ticket => ticket.status === 3).length}</h4>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                    <Row className='mt-3 pe-5 ps-5'>
                        <Col className='col-sm-9 d-flex align-items-center'>

                            <Form.Control
                                type="text"
                                placeholder="Search Ticket by ID"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Col>
                        <Col className='col-sm-3 d-flex align-items-center justify-content-end'>
                            <Button className='fs-6' onClick={handleCreateTicketClick}>Create Ticket</Button>
                        </Col>
                    </Row>


                    <Row style={tableContainerStyle} ref={scrollContainerRef} onScroll={handleScroll}>
                        <Col  >
                            <Table responsive bordered hover>
                                <thead>
                                    <tr>
                                        <th>Incident ID</th>
                                        <th>Title</th>
                                        <th>Assigned To</th>
                                        <th>Status</th>
                                        <th>Created At</th>
                                        <th>SLA</th> {/* New Elapsed Time column */}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredRecords.length > 0 ? (
                                        filteredRecords.map((ticket) => {
                                            const assignedToAgent = agents.find(agent => agent.id === ticket.assignedTo);

                                            // Function to calculate the elapsed time
                                            const calculateElapsedTime = (start, end) => {
                                                const startTime = new Date(start);
                                                const endTime = new Date(end);
                                                const diffInMilliseconds = endTime - startTime;
                                                const diffInSeconds = Math.floor(diffInMilliseconds / 1000);

                                                const days = Math.floor(diffInSeconds / (3600 * 24));
                                                const hours = Math.floor((diffInSeconds % (3600 * 24)) / 3600);
                                                const minutes = Math.floor((diffInSeconds % 3600) / 60);

                                                // Build the elapsed time string
                                                let elapsedTime = '';
                                                if (days > 0) elapsedTime += `${days}d `;
                                                if (hours > 0) elapsedTime += `${hours}h `;
                                                if (minutes > 0) elapsedTime += `${minutes}m`;

                                                return elapsedTime.trim(); // Remove trailing spaces
                                            };

                                            const status = statusText(ticket.status);
                                            const isResolvedOrClosed = [TicketStatus.Resolved, TicketStatus.Closed].includes(status);
                                            const endTime = isResolvedOrClosed ? ticket.updatedAt : new Date();

                                            return (
                                                <tr
                                                    key={ticket.id}
                                                    onClick={() => handleViewClick(ticket)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <td>{ticket.ticketId}</td>
                                                    <td style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                        {ticket.title}
                                                    </td>
                                                    <td>{assignedToAgent ? assignedToAgent.username : 'Unassigned'}</td>
                                                    <td style={statusStyle(status)}>{status}</td>
                                                    <td>{new Date(ticket.createdAt).toLocaleString()}</td>
                                                    <td>
                                                        {isResolvedOrClosed
                                                            ? <span style={{ color: 'green', fontWeight: 'bold' }}>
                                                                {calculateElapsedTime(ticket.createdAt, ticket.updatedAt)}
                                                            </span>
                                                            : calculateElapsedTime(ticket.createdAt, endTime)}
                                                    </td> {/* Conditionally display Elapsed Time */}
                                                    <td>
                                                        <Button variant="info" onClick={() => handleViewClick(ticket)}>View</Button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={7}>No tickets found</td>
                                        </tr>
                                    )}
                                </tbody>
                                {loading && <Spinner animation="border" className="d-block mx-auto" />}
                            </Table>




                        </Col>
                    </Row>
                    <ViewTicketModal
                        show={viewModalShow}
                        onHide={() => setViewModalShow(false)}
                        ticket={selectedTicket}
                        userType={userType}
                    />



                    <Modal show={createTicketModalShow} onHide={() => setCreateTicketModalShow(false)} centered backdrop="static" keyboard={false}>
                        <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>
                            <Modal.Title>Create Ticket</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CreateTicketForm setData={setRecords} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setCreateTicketModalShow(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            )}
        </>
    );
}
