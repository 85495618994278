import React, { useState, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { Container, Table, Button, Modal, Form } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { HashLoader } from 'react-spinners';
import 'bootstrap/dist/css/bootstrap.min.css';

interface IUserDetail {
  id: number;
  role: number;
  subscriptionType: number;
  email: string;
  username: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  address?: string;
  contact: string;
  profileimage?: string | null;
  country: string;
  subscriptions?: ISubscriptions | null;
  createdAt: string;
}

interface ISubscriptions {
  id: number;
  status: number;
  createdAt: string;
  expiresIn: string;
}

interface ITicket {
  id: number;
  title: string;
  desc: string;
  status: number;
  createdAt: string;
  resolution?: string; // Optional resolution field
  updatedAt?:string;
}

const UserView: React.FC = () => {
  const [users, setUsers] = useState<IUserDetail[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUserDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<IUserDetail | null>(null);
  const [isUserModalOpen, setIsUserModalOpen] = useState<boolean>(false);
  const [isTicketsModalOpen, setIsTicketsModalOpen] = useState<boolean>(false);
  const [isNoTicketsModalOpen, setIsNoTicketsModalOpen] = useState<boolean>(false);
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<ITicket | null>(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      setFilteredUsers(users.filter(user =>
        user.id.toString().includes(lowerCaseQuery) ||
        user.email.toLowerCase().includes(lowerCaseQuery) ||
        user.contact.toLowerCase().includes(lowerCaseQuery) ||
        `${user.firstName} ${user.middleName ? user.middleName + ' ' : ''}${user.lastName}`
          .toLowerCase()
          .includes(lowerCaseQuery)
      ));
    } else {
      setFilteredUsers(users);
    }
  }, [searchQuery, users]);

  const fetchUsers = async () => {
    try {
      const page = 1;
      const pageSize = 1000;
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/User/get-all-users`);
      url.searchParams.append('page', page.toString());
      url.searchParams.append('pageSize', pageSize.toString());

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });

      if (response.ok) {
        const result = await response.json();
        console.log('API Result:', result); // Debugging
        setUsers(result);
        setFilteredUsers(result);
      } else {
        setError('Failed to fetch users');
      }
    } catch (error) {
      setError('Error fetching users');
    } finally {
      setLoading(false);
    }
  };

  const fetchUserTickets = async (userId: number) => {
    try {
      const page = 1;
      const pageSize = 10;
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/ticket/user-tickets`);
      url.searchParams.append('userId', userId.toString());
      url.searchParams.append('page', page.toString());
      url.searchParams.append('pageSize', pageSize.toString());

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Tickets Result:', result); // Debugging
        setTickets(result);
        if (result.length === 0) {
          setIsNoTicketsModalOpen(true);
        } else {
          setIsTicketsModalOpen(true);
        }
      } else {
        setError('Failed to fetch tickets');
      }
    } catch (error) {
      setError('Error fetching tickets');
    }
  };
  const formatDateTime = (dateTime: string) => {
    // Parse the ISO 8601 date-time string
    const date = new Date(dateTime);
  
    // Extract day, month, year, hours, minutes, and seconds
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    // Return the formatted string
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setSearchQuery('');
    }
  };

  const handleViewDetails = (user: IUserDetail) => {
    setSelectedUser(user);
    setIsUserModalOpen(true);
  };

  const handleShowTickets = (userId: number) => {
    // closeUserModal();
    fetchUserTickets(userId);
  };

  const closeUserModal = () => {
    setIsUserModalOpen(false);
    setSelectedUser(null);
  };

  const closeTicketsModal = () => {
    setIsTicketsModalOpen(false);
    setSelectedTicket(null);
    setIsUserModalOpen(true); // Reopen the user modal
  };

  const closeNoTicketsModal = () => {
    setIsNoTicketsModalOpen(false);
    setIsUserModalOpen(true); // Reopen the user modal
  };

  const handleShowTicketDetails = (ticket: ITicket) => {
    setSelectedTicket(ticket);
  };
  const getSubscriptionStatus = (subscriptionType: number) => {
    switch (subscriptionType) {
      case 0:
        return 'Not subscribed';
      case 1:
        return 'Subscribed';
      default:
        return 'Not subscribed';
    }
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  const getTicketStatus = (status: number) => {
    switch (status) {
      case 0:
        return 'Open';
      case 1:
        return 'Assigned';
      case 2:
        return 'Resolved';
      case 3:
        return 'Closed';
      default:
        return 'Open';
    }
  };
  // if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-danger text-center">{error}</div>;

  return (
  <>
   {loading ? (
                <div className="d-flex mb-5 justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <HashLoader color="#007bff" size={60} />
                </div>
            ) : (
    <Container className="my-4">
      <div className="mb-4 position-relative">
        <Form.Control
          type="text"
          placeholder="Search by ID, email, or contact..."
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
          style={{ maxWidth: '300px', paddingRight: '30px' }} // Adjust the width and padding for the close icon
        />
        {searchQuery && (
          <Button
            variant="link"
            className="position-absolute"
            style={{ top: '50%', right: '10px', transform: 'translateY(-50%)' }}
            onClick={clearSearch}
          >
            <AiOutlineClose />
          </Button>
        )}
      </div>
      <div className="table-responsive" style={{ maxHeight: '550px' }}>
        <Table striped bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>User ID</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Country</th>
              <th>Contact</th>
              <th>Subscription</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{`${user.firstName} ${user.middleName ? user.middleName + ' ' : ''}${user.lastName}`}</td>
                <td>{user.email}</td>
                <td>{user.country}</td>
                <td>{user.contact}</td>
                <td>{getSubscriptionStatus(user.subscriptionType)}</td>
                {/* <td>{user.subscriptionType}</td> */}
                <td>
                  <Button onClick={() => handleViewDetails(user)} variant="primary" size="sm">
                    View Details
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal show={isUserModalOpen} onHide={closeUserModal} size="lg" centered backdrop="static"   keyboard={false} >
        <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>

          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser && (
            <>
              <p><strong>ID:</strong> {selectedUser.id}</p>
              <p><strong>Full Name:</strong> {`${selectedUser.firstName} ${selectedUser.middleName ? selectedUser.middleName + ' ' : ''}${selectedUser.lastName}`}</p>
              <p><strong>Email:</strong> {selectedUser.email}</p>
              <p><strong>Country:</strong> {selectedUser.country}</p>
              <p><strong>Contact:</strong> {selectedUser.contact}</p>
              <p><strong>Subscription Status:</strong> {getSubscriptionStatus(selectedUser.subscriptionType)}</p>
              {selectedUser.subscriptions && (
                <>
                  <p><strong>Subscription ID:</strong> {selectedUser.subscriptions.id}</p>
                  <p><strong>Subscription Created At:</strong> {selectedUser.subscriptions.createdAt}</p>
                  <p><strong>Subscription Expires In:</strong> {selectedUser.subscriptions.expiresIn}</p>
                </>
              )}
              <Button onClick={() => handleShowTickets(selectedUser.id)} variant="info">
                View Tickets
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={isTicketsModalOpen} onHide={closeTicketsModal} size="xl" centered backdrop="static"   keyboard={false}>
        <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>
          <Modal.Title>User Tickets</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive" style={{ maxHeight: '300px' }}>
            
            <Table striped bordered hover>
              <thead className="thead-dark">
                <tr>
                  <th>Ticket ID</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tickets.map((ticket) => (
                  <tr key={ticket.id}>
                    <td>{ticket.id}</td>
                    <td>{ticket.title}</td>
                    <td>{ticket.desc}</td>
                    <td>{getTicketStatus(ticket.status)}</td>
                    <td>{formatDateTime(ticket.createdAt)}</td>
                    <td>
                      <Button onClick={() => handleShowTicketDetails(ticket)} variant="primary" size="sm">
                        View Details
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer><Button variant="secondary" onClick={closeTicketsModal}>Close</Button></Modal.Footer>
      </Modal>

      <Modal
  show={isNoTicketsModalOpen}
  onHide={closeNoTicketsModal}
  size="sm"
  centered
  style={{
    animation: 'float 3s ease-in-out infinite',
  }}
>
  <Modal.Header
    closeButton
    style={{
      backgroundColor: '#ffc107',
      color: '#000',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    }}
  >
    <Modal.Title>No Tickets Available</Modal.Title>
  </Modal.Header>
  <Modal.Body
    className="text-center"
    style={{ padding: '20px', fontSize: '16px', color: '#333' }}
  >
    <p>No tickets available for this user.</p>
    <Button onClick={closeNoTicketsModal} variant="secondary">
      Close
    </Button>
  </Modal.Body>
</Modal>

<style>
{`
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
`}
</style>


      <Modal show={!!selectedTicket} onHide={() => setSelectedTicket(null)} size="lg" centered backdrop="static"   keyboard={false}>
        <Modal.Header closeButton style={{ backgroundColor: '#eba607', color: '#fff' }}>
          <Modal.Title>Ticket Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTicket && (
            <>
              <p><strong>Ticket ID:</strong> {selectedTicket.id}</p>
              <p><strong>Title:</strong> {selectedTicket.title}</p>
              <p><strong>Description:</strong> {selectedTicket.desc}</p>
              <p><strong>Status:</strong> {getTicketStatus(selectedTicket.status)}</p>
                {(selectedTicket.status === 2 || selectedTicket.status === 3)  && (
                <p><strong>Resolution:</strong> {selectedTicket.resolution}</p>
                )}
              <p><strong>Created At:</strong> {formatDateTime(selectedTicket.createdAt)}</p>
              {selectedTicket.updatedAt && (
        <p><strong>Last Updated At:</strong> {formatDateTime(selectedTicket.updatedAt)}</p>
      )}
              {/* <p><strong>Assigned To:</strong> {selectedTicket.createdAt}</p> */}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
    <Button variant="secondary" onClick={() => setSelectedTicket(null)}>
      Close
    </Button>
  </Modal.Footer>
      </Modal>
    </Container>)}
  </>
  );
};

export default UserView;
