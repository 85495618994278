import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { authToken } from '../../Constants/VariableConstants';
import {
  Container,
  InputGroup,
  Form,
  Button,
  Card,
  Row,
  Col,
  Image,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export default function SignIn() {
  const [email, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage(""); // Reset error message before each login attempt
    try {
      const data = { email, password };

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/account/admin-signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      // Check the content type of the response
      const contentType = response.headers.get('Content-Type');

      if (contentType && contentType.includes('application/json')) {
        const result = await response.json();

        if (response.ok) {
          const token = result.token;
          localStorage.setItem(authToken, token);
          localStorage.setItem('userType', parseInt(result.userType));
          localStorage.setItem('userName', result.username);
          localStorage.setItem('email', result.email);
          localStorage.setItem('userId', result.userId);
          navigate('/dashboard');
          window.location.reload();
        } else if (response.status === 400) {
          setErrorMessage(result.errorMessage || "Invalid Credentials");
        } else {
          setErrorMessage(result.errorMessage || "An error occurred. Please try again.");
        }
      } else {
        const text = await response.text();
        setErrorMessage(text || "An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPasswordClick = () => {
    navigate('/forgot-password');
  };

  return (
    <Container fluid>
      <Row md className="vh-100">
        <Col
          md={6}
          className="d-flex justify-content-center align-items-center position-relative mx-vh-100"
          style={{ backgroundImage: 'linear-gradient(to bottom right, #417599, black)' }}
        >
          <Image
            className="img-fluid"
            style={{
              maxWidth: "50%",
              maxHeight: "60%",
              minWidth: "30%",
              zIndex: 0
            }}
            src="../../Icons/PhishROKO LOgo.png"
          />
        </Col>
        <Col md={6} className="d-flex justify-content-center align-items-center bg-white">
          <Row className="justify-content-center mt-5">
            <Card className="shadow">
              <Card.Header className="text-center text-black bg-light">
                <h1 className="fw-bold mb-0">Login</h1>
              </Card.Header>
              <Card.Body>
                <Form>
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <Image fluid src="../../Icons/fi-rr-user.png" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <Image fluid src="../../Icons/fi-rr-key.png" />
                    </InputGroup.Text>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputGroup.Text>
                      {showPassword ? (
                        <FaEyeSlash onClick={() => setShowPassword(!showPassword)} />
                      ) : (
                        <FaEye onClick={() => setShowPassword(!showPassword)} />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="d-flex justify-content-between align-items-center">
                    <Card.Link href="#" className="text-decoration-none text-primary" onClick={handleForgotPasswordClick}>Forgot your password?</Card.Link>
                    <Button onClick={handleSubmit} disabled={loading}>
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        'Login'
                      )}
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
