import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import { Subscription } from './types';

interface EditPlanModalProps {
  show: boolean;
  onHide: () => void;
  plan: Subscription | null;
}

const EditPlanModal: React.FC<EditPlanModalProps> = ({ show, onHide, plan }) => {
  const [formData, setFormData] = useState({
    id: '',
    planId: '',
    name: '',
    descriptionLines: Array(7).fill(''),
    country: '',
    currency: '',
    price: '',
    validity: '',
    status: 'active',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (plan) {
      const descriptionLines = plan.description.split('~').filter(line => line.trim() !== '');
      setFormData({
        id: plan.id,
        planId: plan.planId,
        name: plan.name,
        descriptionLines: [...descriptionLines, ...Array(7 - descriptionLines.length).fill('')],
        country: plan.country,
        currency: plan.currency,
        price: plan.price,
        validity: plan.validity,
        status: plan.status || 'active',
      });
    }
  }, [plan]);

  const handleChange = (index: number, value: string) => {
    setFormData(prev => ({
      ...prev,
      descriptionLines: prev.descriptionLines.map((line, i) => (i === index ? value : line))
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowConfirm(true); // Show confirmation modal
  };

  const handleConfirmSave = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    setShowConfirm(false); // Hide confirmation modal

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/Subscription/update-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({
          planId: formData.planId,
          name: formData.name,
          description: formData.descriptionLines.join('~'),
          validity: Number(formData.validity),
          country: formData.country,
          currency: formData.currency,
          price: Number(formData.price),
          id: Number(formData.id),
        }),
      });

      if (response.ok) {
        const result = await response.json();
        setSuccess('Plan edited successfully!');
        setShowSuccessModal(true); // Show success modal
        // Clear form data
        setFormData({
          id: '',
          planId: '',
          name: '',
          descriptionLines: Array(7).fill(''),
          country: '',
          currency: '',
          price: '',
          validity: '',
          status: 'active',
        });
      } else {
        const result = await response.json();
        throw new Error(result.message || 'Something went wrong');
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmCancel = () => {
    setShowConfirm(false); // Hide confirmation modal
  };

  const handleSuccessClose = () => {
    setShowSuccessModal(false); // Close success modal
    onHide(); // Close all modals
    window.location.reload(); // Refresh the page
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="md" centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          {loading && <Spinner animation="border" />}
          {plan && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formId">
                <Form.Label>Plan ID</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.planId}
                  readOnly
                />
              </Form.Group>
              <Form.Group controlId="formName">
                <Form.Label>Plan Name</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.name}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                {formData.descriptionLines.map((line, index) => (
                  <div key={index} style={{ marginBottom: '10px' }}>
                    <Form.Label>{index < 5 ? `Line ${index + 1}` : `Footer ${index - 4}`}</Form.Label>
                    <Form.Control
                      type="text"
                      value={line}
                      onChange={(e) => handleChange(index, e.target.value)}
                      placeholder={`Description ${index < 5 ? `Line ${index + 1}` : `Footer ${index - 4}`}`}
                    />
                  </div>
                ))}
              </Form.Group>
              <Form.Group controlId="formCountry">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.country}
                  readOnly
                />
              </Form.Group>
              <Form.Group controlId="formCurrency">
                <Form.Label>Currency</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.currency}
                  readOnly
                />
              </Form.Group>
              <Form.Group controlId="formPrice">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.price}
                  readOnly
                />
              </Form.Group>
              <Form.Group controlId="formValidity">
                <Form.Label>Validity (Days)</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.validity}
                  onChange={(e) => setFormData({ ...formData, validity: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={formData.status}
                  onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Control>
              </Form.Group>
              <Button
                type="submit"
                variant="primary"
                style={{ marginTop: '20px' }}
                disabled={loading}
              >
                {loading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
              </Button>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirm} onHide={handleConfirmCancel} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Save</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to save the changes?
          {success && <Alert variant="success" style={{ marginTop: '10px' }}>{success}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmCancel}>Cancel</Button>
          <Button variant="primary" onClick={handleConfirmSave} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSuccessModal} onHide={handleSuccessClose} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {success}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSuccessClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditPlanModal;
