import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  InputGroup,
  Form,
  Button,
  Card,
  Row,
  Col,
  Image,
  Modal,
} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa'; // Import back arrow icon
import { FaEnvelope } from 'react-icons/fa'; // Use FontAwesome for the email icon

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/account/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const result = await response.json();

      if (response.ok) {
        setShowModal(true); // Show modal on successful submission
      } else {
        alert("Error sending password reset link");
        console.error('Forgot Password request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <Container fluid>
      <Row md className="vh-100">
        <Col
          md={6}
          className="d-flex justify-content-center align-items-center position-relative mx-vh-100"
          style={{ backgroundImage: 'linear-gradient(to bottom right, #417599, black)' }}
        >
          <Image
            className="img-fluid"
            style={{
              maxWidth: "50%",
              maxHeight: "60%",
              minWidth: "30%",
              zIndex: 0
            }}
            src="../../Icons/PhishROKO LOgo.png"
          />
        </Col>
        <Col md={6} className="d-flex justify-content-center align-items-center bg-white">
          <Row className="justify-content-center mt-5">
            <Card className="shadow">
              <Card.Header className="text-center text-black bg-light">
                <h1 className="fw-bold mb-0">Forgot Password</h1>
              </Card.Header>
              <Card.Body>
                <Form>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <FaEnvelope />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                  <div className="d-flex justify-content-between">
                    <Button variant="link" onClick={handleBackClick}>
                      <FaArrowLeft /> Back
                    </Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Row>
        </Col>
      </Row>

      {/* Modal for success message */}
      <Modal show={showModal} onHide={handleCloseModal}centered backdrop="static"   keyboard={false} >
        <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>

          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your new password has been sent to your email.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
