// SubscriptionPage.jsx
import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardText, MDBBtn, MDBCardHeader, MDBCardFooter } from 'mdb-react-ui-kit';
import './subscriptionStyle.css';

interface CountryPricing {
    id: number;
    country: string;
    currency: string;
    price: string;
    createdAt: string;
    updatedAt: string;
}

interface Plan {
    id: number;
    planId: string;
    name: string;
    description: string;
    createdAt: string;
    created_at: string;
    updatedAt: string;
    countryPricings: CountryPricing[];
}

type PlanData = Plan[];

const initialPlans: PlanData = [
    {
        id: 0,
        planId: "",
        name: "",
        description: "",
        createdAt: "",
        created_at: "",
        updatedAt: "",
        countryPricings: [
            {
                id: 0,
                country: "US",
                currency: "USD",
                price: "",
                createdAt: "",
                updatedAt: ""
            }
        ]
    }
];

export default function SubscriptionPage() {
    const [subscription, setSubscription] = useState<PlanData>(initialPlans);
    const [country, setCountry] = useState('US');
    const [selectedPlan, setSelectedPlan] = useState<string>('basic-plan');
    const [currentCountry, setCurrentCountry] = useState<string>('USD');
    const token = localStorage.getItem('AuthToken');
    const userId = localStorage.getItem('userId');
    const userEmail = localStorage.getItem('email');
    const getCurrencyCode = (country: string) => {
        if (country === 'In') return 'INR';
        return 'USD';
    };


    useEffect(() => {
        const fetchSubscription = async () => {
            try {
                const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/get-all`);
                const response = await fetch(url.toString(), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const result = await response.json();
                    setSubscription(result.data);
                } else {
                    console.error('Failed to fetch:', response.status, response.statusText);
                }
            } catch (error) {
                console.error('Error during fetch:', error);
            }
        };
        fetchSubscription();
    }, []);


    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
          const script = document.createElement('script');
          script.src = 'https://checkout.razorpay.com/v1/checkout.js';
          script.onload = () => {
            resolve(true);
          };
          script.onerror = () => {
            resolve(false);
          };
          document.body.appendChild(script);
        });
      };
      

    const proceedToPay = async (planId: string, price: string) => {
        const razorpayLoaded = await loadRazorpayScript();
        if (!razorpayLoaded) {
            alert('Razorpay SDK failed to load. Please try again later.');
            return;
        }

        const options = {
            description: 'Subscription Payment',
            image: 'https://i.imgur.com/Sk4ncDV.png',
            currency: getCurrencyCode(country),
            key: 'rzp_test_aY6FLjVPIqSHzL', // Replace with your test key
            amount: price,
            name: 'Phisho',
            prefill: {
                email: 'ravi99@test.com',
                contact: '9999999999',
                name: 'Customer Name',
                userID: 13
            },
            theme: { color: '#3399cc' },
            handler: async (response: any) => {
                try {
                    const paymentData = {
                        razorpay_payment_id: response.razorpay_payment_id,
                        userId: userId, // Replace with actual user ID
                        subscriptionPlan: planId
                    };
                    const result = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/verify-payment`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        },
                        body: JSON.stringify(paymentData),
                    }).then(res => res.json());

                    if (result.status === 'success') {
                        console.log(`Payment Successful: ${response.razorpay_payment_id}`);
                        alert('Payment successful');
                    } else {
                        console.error('Payment verification failed');
                        alert('Payment verification failed');
                    }
                } catch (error) {
                    console.error(`Verification Error: ${error}`);
                    alert('Payment verification error. Please try again later.');
                }
            },
            modal: {
                ondismiss: () => {
                    console.log('Checkout form closed');
                },
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    };

    return (
        <MDBContainer className="my-1 subscription-container" style={{ overflowY: "scroll" }}>
            <MDBRow>
                <MDBCol md="12" className="text-center mb-3">
                    <h2 className="h1-responsive font-weight-bold text-center my-3">
                        Our Pricing Plans
                    </h2>
                    <p className="grey-text text-center w-responsive mx-auto mb-5">
                        Choose the plan that best suits your needs. Enjoy a variety of features with each plan.
                    </p>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                {subscription.map((plan, index) => (
                    <MDBCol md="4" className="mb-5" key={index}>
                        <MDBCard className="h-100 elegant-card">
                            <MDBCardHeader tag="h4" className="mb-4 text-info">Plan</MDBCardHeader>
                            <MDBCardBody className="text-center">
                               
                                <MDBBtn color="primary" onClick={() => proceedToPay("test-plan1","30")} rounded>
                                    Choose Plan
                                </MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                ))}
            </MDBRow>
        </MDBContainer>
    );
};


