import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';

interface ViewTicketModalProps {
    show: boolean;
    onHide: () => void;
    ticket: any | null; // Replace `any` with a specific type if possible
    userType: number; // Add userType prop
}

enum Status {
    Open = 0,
    Assigned = 1,
    Resolved = 2,
    Closed = 3,
}

const statusColors: { [key in Status]: string } = {
    [Status.Open]: '#007bff', // Blue
    [Status.Assigned]: '#ffc107', // Yellow
    [Status.Resolved]: '#28a745', // Green
    [Status.Closed]: '#dc3545', // Red
};

const ViewTicketModal: React.FC<ViewTicketModalProps> = ({ show, onHide, ticket, userType }) => {
    const [showAgentModal, setShowAgentModal] = useState(false);
    const [agents, setAgents] = useState<any[]>([]);
    const [selectedAgent, setSelectedAgent] = useState<number | null>(null);
    const [selectedAgentName, setSelectedAgentName] = useState<string>('');
    const [resolveDescription, setResolveDescription] = useState('');
    const [agentSearchTerm, setAgentSearchTerm] = useState('');
    const [filteredAgents, setFilteredAgents] = useState<any[]>([]);
    const [showResolveTextBox, setShowResolveTextBox] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<'success' | 'error'>('success');
    const [actionType, setActionType] = useState<'reassign' | 'escalate' | 'resolve' | null>(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [imageForModal, setImageForModal] = useState('');
    const [showImageModal, setShowImageModal] = useState(false);

    const handleAttachmentClick = (attachmentUrl: string) => {
        const fileExtension = attachmentUrl.split('.').pop()?.toLowerCase();
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];

        if (fileExtension && imageExtensions.includes(fileExtension)) {
            setImageForModal(attachmentUrl);
            setShowImageModal(true);
        } else {
            window.open(attachmentUrl, '_blank');
        }
    };

    useEffect(() => {
        const fetchAgents = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/User/get-all-agents?page=1&pageSize=100`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                        },
                    }
                );
                if (response.ok) {
                    const data = await response.json();
                    setAgents(data || []);
                } else {
                    console.error('Failed to fetch agents');
                }
            } catch (error) {
                console.error('Error fetching agents:', error);
            }
        };

        fetchAgents();
    }, []);

    useEffect(() => {
        if (userType === 2 && ticket) {
            setFilteredAgents(agents.filter((agent) => agent.role === 1)); // Only specialists for userType 2
        } else {
            setFilteredAgents(agents); // Show all agents for userType 0 and 1
        }
    }, [userType, agents, ticket]);

    const handleAgentSelect = (agent: any) => {
        setSelectedAgent(agent.id);
        setSelectedAgentName(`${agent.firstName} ${agent.lastName}`);
        setShowAgentModal(false);
        setShowConfirmationModal(true); // Show the confirmation modal when an agent is selected
    };

    const handleResolve = async () => {
        if (ticket) {
            const payload = {
                id: ticket.id,
                title: ticket.title,
                desc: ticket.desc,
                entityType: ticket.entityType,
                resolution: resolveDescription, // Use the resolution description provided by the user
                attachment: ticket.attachment,
                status: 2, // Set status to Resolved
                assignTo: ticket.assignTo, // Keep the current assignment
                assignFor: ticket.assignTo,
            };

            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/Ticket/update-ticket`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                        },
                        body: JSON.stringify(payload),
                    }
                );

                if (response.ok) {
                    setMessage('Ticket resolved successfully!');
                    setMessageType('success');
                    setShowMessageModal(true);
                    setTimeout(() => {
                        onHide(); // Close the ticket details view modal after successful resolution
                    }, 1000);
                } else {
                    setMessage('Failed to resolve ticket.');
                    setMessageType('error');
                    setShowMessageModal(true);
                }
            } catch (error) {
                console.error('Error resolving ticket:', error);
                setMessage('An error occurred while resolving the ticket.');
                setMessageType('error');
                setShowMessageModal(true);
            }
        }
    };

    const handleResolveClick = () => {
        setActionType('resolve'); // Set action type
        setShowResolveTextBox(true);
    };

    const handleConfirmResolution = () => {
        setShowConfirmationModal(true); // Show confirmation modal when submitting resolution
    };

    const handleConfirmResolutionAction = async () => {
        setShowConfirmationModal(false);
        await handleResolve();
    };

    const handleCancelResolution = () => {
        setShowResolveTextBox(false);
        setResolveDescription('');
    };

    const handleReassignOrEscalate = async () => {
        if (ticket && selectedAgent !== null) {
            const payload = {
                id: ticket.id,
                title: ticket.title,
                desc: ticket.desc,
                entityType: ticket.entityType,
                resolution: '', // No resolution for re-assign or escalate
                attachment: ticket.attachment,
                status: 1, // Set status to Assigned
                assignTo: selectedAgent,
            };

            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/Ticket/update-ticket`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                        },
                        body: JSON.stringify(payload),
                    }
                );

                if (response.ok) {
                    setMessage('Ticket updated successfully!');
                    setMessageType('success');
                    setShowMessageModal(true);
                    setTimeout(() => {
                        onHide(); // Close the ticket details view modal
                    }, 1000);
                } else {
                    setMessage('Failed to update ticket.');
                    setMessageType('error');
                    setShowMessageModal(true);
                }
            } catch (error) {
                console.error('Error updating ticket:', error);
                setMessage('An error occurred while updating the ticket.');
                setMessageType('error');
                setShowMessageModal(true);
            }
        }
    };

    const handleCloseTicket = async () => {
        if (ticket) {
            const payload = {
                id: ticket.id,
                title: ticket.title,
                desc: ticket.desc,
                entityType: ticket.entityType,
                resolution: ticket.resolution,
                attachment: ticket.attachment,
                status: 3, // Set status to Closed
                assignTo: ticket.assignedTo, // Keep current assignee
            };

            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/Ticket/update-ticket`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                        },
                        body: JSON.stringify(payload),
                    }
                );

                if (response.ok) {
                    setMessage('Ticket closed successfully!');
                    setMessageType('success');
                } else {
                    setMessage('Failed to close ticket.');
                    setMessageType('error');
                }
                setShowMessageModal(true);
            } catch (error) {
                console.error('Error closing ticket:', error);
                setMessage('An error occurred while closing the ticket.');
                setMessageType('error');
                setShowMessageModal(true);
            }
        }
    };

    const handleConfirmAction = async () => {
        setShowConfirmationModal(false);
        if (actionType === 'reassign' || actionType === 'escalate') {
            await handleReassignOrEscalate();
        } else if (actionType === 'resolve') {
            await handleConfirmResolutionAction();
        }
    };

    const handleConfirmClose = () => {
        handleCloseTicket();
        setShowConfirm(false); // Close the modal after confirming
    };

    if (!ticket) {
        return null;
    }

    return (
        <>
            {/* Ticket Details Modal */}
            <Modal show={show} onHide={onHide} centered backdrop="static" keyboard={false} size="md">
                <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>
                    <Modal.Title>Incident Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Ticket ID: </strong>
                        {ticket.ticketId}
                    </p>
                    <p>
                        <strong>Title: </strong>
                        {ticket.title}
                    </p>
                    <p>
                        <strong>Description:</strong> {ticket.desc}
                    </p>
                    <p>
                        <strong>Attachment:</strong>{' '}
                        {ticket.attachment ? (
                            <a
                                href="#"
                                style={{
                                    color: 'blue',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleAttachmentClick(ticket.attachment);
                                }}
                            >
                                View Attachment
                            </a>
                        ) : (
                            <span style={{ color: 'gray', fontStyle: 'italic' }}>No attachment</span>
                        )}
                    </p>

                    <p>
                        <strong>Status:</strong>{' '}
                        <span style={{ color: statusColors[ticket.status] }}>
                            {Status[ticket.status]}
                        </span>
                    </p>
                    <p>
                        <strong>Assigned To:</strong> {ticket.assignedToUser}
                    </p>
                    <p>
                        <strong>Created At:</strong> {new Date(ticket.createdAt).toLocaleString()}
                    </p>
                    <p>
                        <strong>Last Updated At:</strong> {new Date(ticket.updatedAt).toLocaleString()}
                    </p>
                    {(ticket.status === 2 || ticket.status === 3) && (
    <p>
        <strong>Resolution:</strong>
        <br />
        {ticket.resolution.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ))}
    </p>
)}


                    {/* Action Buttons */}
                    {ticket.status === 0 && userType !== 2 && !showResolveTextBox && (
                        <Button
                            variant="primary"
                            onClick={() => {
                                setActionType('reassign');
                                setShowAgentModal(true);
                            }}
                        >
                            Assign
                        </Button>
                    )}
                    {ticket.status === 1 && !showResolveTextBox && userType === 2 && (
                        <>
                            <Button
                                variant="warning"
                                onClick={() => {
                                    setActionType('escalate');
                                    setShowAgentModal(true);
                                }}
                                className="me-2"
                            >
                                Escalate
                            </Button>
                            <Button variant="success" onClick={handleResolveClick}>
                                Resolve
                            </Button>
                        </>
                    )}
                    {ticket.status === 1 && !showResolveTextBox && userType !== 2 && (
                        <>
                            <Button
                                variant="warning"
                                onClick={() => {
                                    setActionType('reassign');
                                    setShowAgentModal(true);
                                }}
                                className="me-2"
                            >
                                Re-assign
                            </Button>
                            <Button
                                variant="success"
                                onClick={() => {
                                    setActionType('resolve');
                                    handleResolveClick();
                                }}
                            >
                                Resolve
                            </Button>
                        </>
                    )}
                    {ticket.status === 2 && userType !== 2 && (
                        <Button variant="danger" onClick={() => setShowConfirm(true)}>
                            Close Ticket
                        </Button>
                    )}

                    {/* Confirmation Modal for Closing Ticket */}
                    <Modal
                        show={showConfirm}
                        onHide={() => setShowConfirm(false)}
                        centered
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton style={{ backgroundColor: '#ff1100', color: '#fff' }}>
                            <Modal.Title>Confirm Close Ticket</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to close this ticket?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowConfirm(false)}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={handleConfirmClose}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Resolution Textbox */}
                    {showResolveTextBox && (
                        <Form.Group controlId="formResolveDescription">
                            <Form.Label>Resolution Description</Form.Label>
                            <div style={{ position: 'relative' }}>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    maxLength={1000}
                                    value={resolveDescription}
                                    onChange={(e) => setResolveDescription(e.target.value)}
                                    style={{ paddingRight: '80px' }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '0',
                                        right: '10px',
                                        fontSize: '0.875rem',
                                        color: '#6c757d',
                                    }}
                                >
                                    {resolveDescription.length}/1000
                                </div>
                            </div>
                            <div style={{ marginTop: '15px' }}>
                                <Button
                                    variant="primary"
                                    onClick={handleConfirmResolution}
                                    style={{ marginRight: '10px' }}
                                    disabled={!resolveDescription.trim()} // Disable if empty
                                >
                                    Submit Resolution
                                </Button>
                                <Button variant="secondary" onClick={handleCancelResolution}>
                                    Cancel
                                </Button>
                            </div>
                        </Form.Group>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Agent Selection Modal */}
            <Modal
                show={showAgentModal}
                onHide={() => setShowAgentModal(false)}
                size="lg"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select Agent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        type="text"
                        placeholder="Search agents..."
                        value={agentSearchTerm}
                        onChange={(e) => setAgentSearchTerm(e.target.value)}
                    />
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredAgents
                                .filter(
                                    (agent) =>
                                        agent.firstName.toLowerCase().includes(agentSearchTerm.toLowerCase()) ||
                                        agent.lastName.toLowerCase().includes(agentSearchTerm.toLowerCase())
                                )
                                .map((agent) => (
                                    <tr key={agent.id}>
                                        <td>
                                            {agent.firstName} {agent.lastName}
                                        </td>
                                        <td>{agent.email}</td>
                                        <td>
                                            <Button variant="primary" onClick={() => handleAgentSelect(agent)}>
                                                Select
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAgentModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Confirmation Modal */}
            <Modal
                show={showConfirmationModal}
                onHide={() => setShowConfirmationModal(false)}
                size="md"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>
                    <Modal.Title>Confirm Action</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center align-items-center" style={{ minHeight: '150px' }}>
                    <p>
                        Are you sure you want to{' '}
                        {actionType === 'reassign'
                            ? `re-assign`
                            : actionType === 'escalate'
                            ? `escalate`
                            : `resolve`}{' '}
                        this ticket{' '}
                        {actionType === 'reassign' || actionType === 'escalate'
                            ? `to ${selectedAgentName}`
                            : ''}?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={handleConfirmAction}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Message Modal */}
            <Modal
                show={showMessageModal}
                onHide={() => setShowMessageModal(false)}
                size="md"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header
                    closeButton
                    style={{ backgroundColor: messageType === 'success' ? '#28a745' : '#dc3545', color: '#fff' }}
                >
                    <Modal.Title>{messageType === 'success' ? 'Success' : 'Error'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowMessageModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Image Modal */}
            <Modal show={showImageModal} onHide={() => setShowImageModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Image Attachment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={imageForModal} alt="Attachment" style={{ width: '100%' }} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ViewTicketModal;
