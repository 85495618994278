import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import SignIn from './account/SignIn.js';
import ForgotPassword from './account/ForgotPassword.js';
import Home from './Home.tsx';

export default function App({ socket }) {
  const isAuthenticated = !!localStorage.getItem('userType'); // Check if user is authenticated

  return (
    <div className='Container' style={{ height: '100%' }}>
      <Routes>
        {/* Non-authenticated Routes */}
        <Route path="/" element={<SignIn />} />
        <Route path="forgot-password" element={<ForgotPassword />} />

        {/* Authenticated Routes */}
        {isAuthenticated ? (
          <Route path="/*" element={<Home socket={socket} />} />
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}
      </Routes>
    </div>
  );
}
