import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import EditPlanModal from './EditPlanModal.tsx';  // Adjust the path as necessary
import CreatePlanModal from './CreatePlanModal.tsx';  // Adjust the path as necessary
import { Subscription } from './types';  // Adjust the path as necessary
import ViewPlanModal from './ViewPlanModal.tsx';  // Adjust the path as necessary

const PriceManagement = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [showViewPopup, setShowViewPopup] = useState<boolean>(false);
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<Subscription | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/Subscription/get-plans`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          }
        });
        if (response.ok) {
          const result = await response.json();
          // Sort subscriptions by country in ascending order
          const sortedSubscriptions = result.sort((a: Subscription, b: Subscription) => a.country.localeCompare(b.country));
          setSubscriptions(sortedSubscriptions);
        } else {
          setError('Failed to fetch data');
        }
      } catch (err) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const handleViewClick = (plan: Subscription) => {
    setSelectedPlan(plan);
    setShowViewPopup(true);
  };

  const handleEditClick = () => {
    setShowEditPopup(true);
    setShowViewPopup(false);
  };

  const handleCreateClick = () => {
    setShowCreatePopup(true);
  };

  const handleCloseView = () => setShowViewPopup(false);
  const handleCloseEdit = () => setShowEditPopup(false);
  const handleCloseCreate = () => setShowCreatePopup(false);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mt-4" style={{ height: '85vh', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem', marginRight: '1rem' }}>
        <Button onClick={handleCreateClick}>
          Create New Plan
        </Button>
      </div>
      <div style={{ flex: 1, overflowY: 'auto' }}>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Plan Name</th>
              <th>Plan ID</th>
              <th>Description</th>
              <th>Country</th>
              <th>Currency</th>
              <th>Price</th>
              <th>Validity</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {subscriptions.map((subscription) => (
              <tr key={subscription.id}>
                <td>{subscription.name}</td>
                <td>{subscription.planId}</td>
                <td>{subscription.description.length > 50 ? `${subscription.description.slice(0, 50)}...` : subscription.description}</td>
                <td>{subscription.country}</td>
                <td>{subscription.currency}</td>
                <td>{subscription.price}</td>
                <td>{subscription.validity}</td>
                <td>
                  <Button onClick={() => handleViewClick(subscription)} variant="info">View</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* View Plan Modal */}
      <ViewPlanModal
        show={showViewPopup}
        onHide={handleCloseView}
        plan={selectedPlan}
        onEditClick={handleEditClick} // Pass the handler for editing
      />

      {/* Edit Plan Modal */}
      <EditPlanModal
        show={showEditPopup}
        onHide={handleCloseEdit}
        plan={selectedPlan}
      />

      {/* Create Plan Modal */}
      <CreatePlanModal
        show={showCreatePopup}
        onHide={handleCloseCreate}
      />
    </div>
  );
};

export default PriceManagement;
