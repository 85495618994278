import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import axios from 'axios';

const countryCodes = {
  USA: 'USA',
  Canada: 'CAN',
  India: 'IND',
  Australia: 'AUS',
  UK: 'UK',
  'New Zealand': 'NZ'
};

const currencyCodes = {
  USA: { currency: 'USD', symbol: '$' },
  Canada: { currency: 'CAD', symbol: 'CA$' },
  India: { currency: 'INR', symbol: '₹' },
  Australia: { currency: 'AUD', symbol: 'AU$' },
  'New Zealand': { currency: 'NZD', symbol: 'NZ$' },
  UK: { currency: 'GBP', symbol: '£' },
};

interface CreatePlanModalProps {
  show: boolean;
  onHide: () => void;
  onCreate: (planData: any) => void; // Add a callback for handling the creation of a new plan
}

const CreatePlanModal: React.FC<CreatePlanModalProps> = ({ show, onHide, onCreate }) => {
  const [formData, setFormData] = useState({
    name: '',
    descriptionLines: Array(7).fill(''),
    country: '',
    currency: '',
    price: '',
    validity: ''
  });

  const [loading, setLoading] = useState(false);
  const [responseModal, setResponseModal] = useState<{ show: boolean, message: string }>({ show: false, message: '' });

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value;
    const selectedCurrency = currencyCodes[selectedCountry] || { currency: '', symbol: '' };

    setFormData(prev => ({
      ...prev,
      country: selectedCountry,
      currency: selectedCurrency.currency
    }));
  };

  const handleChange = (index: number, value: string) => {
    setFormData(prev => ({
      ...prev,
      descriptionLines: prev.descriptionLines.map((line, i) => (i === index ? value : line))
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const description = formData.descriptionLines.filter(line => line.trim()).join('~'); // Filter out empty lines

    const planData = {
      name: formData.name,
      planId: formData.name, // Use plan name as planId
      description,
      country: countryCodes[formData.country], // Use country code
      currency: formData.currency, // Use currency symbol
      price: parseFloat(formData.price),
      validity: parseInt(formData.validity, 10)
    };

    setLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('No auth token found');
      }

      const response = await axios.post('https://phishroko-api.azurewebsites.net/api/Subscription/create-plan', planData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setResponseModal({
          show: true,
          message: 'Plan created successfully'
        });
      }

      setFormData({
        name: '',
        descriptionLines: Array(7).fill(''),
        country: '',
        currency: '',
        price: '',
        validity: ''
      });
    } catch (error) {
      console.error('Error creating plan:', error);
      setResponseModal({
        show: true,
        message: 'Failed to create plan'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResponseClose = () => {
    setResponseModal({ show: false, message: '' });
    onHide(); // Close all modals
    window.location.reload(); // Refresh the page
  };

  const handleClose = () => {
    setFormData({
      name: '',
      descriptionLines: Array(7).fill(''),
      country: '',
      currency: '',
      price: '',
      validity: ''
    });
    onHide();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Create Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Plan Name</Form.Label>
              <Form.Control
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              {formData.descriptionLines.map((line, index) => (
                <Row key={index} className="mb-3">
                  <Col sm="2">
                    <Form.Label>Line {index + 1}</Form.Label>
                  </Col>
                  <Col sm="10">
                    <Form.Control
                      type="text"
                      value={line}
                      onChange={(e) => handleChange(index, e.target.value)}
                      placeholder={`Description Line ${index + 1}`}
                      style={{ paddingLeft: '10px' }} // Add padding
                      required={index < 5} // Make the first 5 lines required
                    />
                  </Col>
                </Row>
              ))}
            </Form.Group>
            <Form.Group controlId="formCountry">
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                value={formData.country || ''}
                onChange={handleCountryChange}
                required
              >
                <option value="">Select a country</option>
                {Object.keys(countryCodes).map(countryCode => (
                  <option key={countryCode} value={countryCode}>
                    {countryCode}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formCurrency">
              <Form.Label>Currency</Form.Label>
              <Form.Control
                type="text"
                value={formData.currency}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="formPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="formValidity">
              <Form.Label>Validity (Days)</Form.Label>
              <Form.Control
                type="number"
                value={formData.validity}
                onChange={(e) => setFormData({ ...formData, validity: e.target.value })}
                required
              />
            </Form.Group>
            <Button type="submit" variant="primary" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Create Plan'}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={responseModal.show} onHide={handleResponseClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{responseModal.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleResponseClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreatePlanModal;
