import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Subscription } from './types';

interface ViewPlanModalProps {
  show: boolean;
  onHide: () => void;
  plan: Subscription | null;
  onEditClick: () => void; // Add this prop
}

const ViewPlanModal: React.FC<ViewPlanModalProps> = ({ show, onHide, plan, onEditClick }) => {
  if (!plan) return null;

  const descriptionLines = plan.description.split('~').filter(line => line.trim() !== '');
  const lastTwoLines = descriptionLines.slice(-2).join(' ');
  const remainingLines = descriptionLines.slice(0, -2);

  return (
    <Modal show={show} onHide={onHide} size="md" centered backdrop="static"   keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>View Plan Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5><strong>Plan Name:</strong> {plan.name}</h5>
        <p><strong>Plan ID:</strong> {plan.planId}</p>
        <p><strong>Description:</strong></p>
        <ul>
          {remainingLines.map((line, index) => (
            <li key={index}>
              <i className="bi bi-list"></i> {line}
            </li>
          ))}
          {lastTwoLines && (
            <li>
              <strong>{lastTwoLines}</strong>
            </li>
          )}
        </ul>
        <p><strong>Country:</strong> {plan.country}</p>
        <p><strong>Currency:</strong> {plan.currency}</p>
        <p><strong>Price:</strong> {plan.price}</p>
        <p><strong>Validity:</strong> {plan.validity} months</p>
        <Button onClick={onEditClick} variant="warning">Edit Plan</Button> {/* Use the onEditClick prop */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewPlanModal;
