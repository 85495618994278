import React, { useState } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';

// Define the Role enum
enum Role {
  AGENT = 2,
  Specialist = 1,
}

const CreateUserForm: React.FC<{ setAgent: any }> = ({ setAgent }) => {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    middleName: '',
    contact: '',
    address: '',
    country: 'INDIA',
    role: Role.AGENT, // Default role
  });

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isloading, setLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Restrict contact field to 10 digits
    if (name === 'contact') {
      if (/^\d{0,10}$/.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, role: Number(e.target.value) });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = {
      ...formData,
      username: formData.firstName, // Ensure username is set to firstName
    };
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/User/create-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const result = await response.json();

        setModalMessage('User created successfully!');
        // Clear the form data
        setFormData({
          email: '',
          firstName: '',
          lastName: '',
          middleName: '',
          contact: '',
          address: '',
          country: 'INDIA',
          role: Role.AGENT, // Reset to default role
        });

        setAgent(prevRecords => [...prevRecords, result]);
      } else if (response.status === 400) {
        const result = await response.text();
        setModalMessage(`Failed to create user: ${result}`);
      } else {
        const result = await response.json();
        setModalMessage(`Failed to create user: ${result.message}`);
      }
      setShowModal(true);
    } catch (error) {
      console.error('Error creating user:', error);
      setModalMessage('An error occurred while creating the user.');
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="container mt-4">
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md="6">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              type="text"
              name="middleName"
              value={formData.middleName}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md="6">
            <Form.Label>Contact</Form.Label>
            <Form.Control
              type="text"
              name="contact"
              value={formData.contact}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="text"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Role</Form.Label>
          <div>
            <Form.Check
              type="radio"
              id="roleAgent"
              name="role"
              value={Role.AGENT}
              label="Agent"
              checked={formData.role === Role.AGENT}
              onChange={handleRoleChange}
            />
            <Form.Check
              type="radio"
              id="roleSpecialist"
              name="role"
              value={Role.Specialist}
              label="Specialist"
              checked={formData.role === Role.Specialist}
              onChange={handleRoleChange}
            />
          </div>
        </Form.Group>

        <Button type="submit" variant="primary" disabled={isloading}>
          <ClipLoader hidden={!isloading} color="#ffffff" size={10} className='me-1' />
          Create User
        </Button>
      </Form>

      {/* Modal for displaying messages */}
      <Modal show={showModal} onHide={handleCloseModal} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateUserForm;
