import React, { useEffect, useState } from 'react';
import { Container, Button, Modal, Form, Table, Spinner, InputGroup, FormControl, Alert } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { FaFilter } from 'react-icons/fa';
import { UrlState } from '../../Constants/Enums.ts';
import { HashLoader } from 'react-spinners';
import 'react-datepicker/dist/react-datepicker.css';

interface TableRecord {
    id: number;
    url: string;
    state: UrlState;
    createdBy: number;
    createdAt: string;
    updatedAt: string;
}

export default function UrlManagement() {
    const [records, setRecords] = useState<TableRecord[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<TableRecord | null>(null);
    const [editing, setEditing] = useState(false);
    const [showInsertModal, setShowInsertModal] = useState(false);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [newUrl, setNewUrl] = useState('');
    const [newUrlState, setNewUrlState] = useState<UrlState>(UrlState.Unverified);
    const [inserting, setInserting] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [modalError, setModalError] = useState('');
    const [userType, setUserType] = useState<number | null>(null);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [selectedStates, setSelectedStates] = useState<UrlState[]>([]);
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [filterActive, setFilterActive] = useState(false);
    

    useEffect(() => {
        const fetchUrl = async () => {
            try {
                const page = 1;
                const pageSize = 1000;
                const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/links/get-links`);
                url.searchParams.append('page', page.toString());
                url.searchParams.append('pageSize', pageSize.toString());

                const response = await fetch(url.toString(), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                });
                if (response.ok) {
                    const result = await response.json();
                    setRecords(result);
                } else {
                    console.error('Failed to fetch links:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching links:', error);
            } finally {
                setLoading(false);
            }
        };

        const type = localStorage.getItem('userType');
        setUserType(type ? parseInt(type, 10) : null);

        fetchUrl();
    }, []);

    const handleViewDetails = (record: TableRecord) => {
        setSelectedRecord(record);
        setEditing(false); // View mode
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedRecord(null);
        setEditing(false); // Reset to view mode
    };

    const handleEditRecord = () => {
        if (userType !== 2) {
            setEditing(true); // Switch to edit mode
        }
    };

    const handleSaveEdit = async () => {
        if (selectedRecord) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/links/update-link`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    body: JSON.stringify({
                        id: selectedRecord.id,
                        state: selectedRecord.state
                    })
                });
                if (response.ok) {
                    const result = await response.json();
                    setRecords(prevRecords =>
                        prevRecords.map(record =>
                            record.id === result.id ? result : record
                        )
                    );
                    setResponseMessage('Record updated successfully.');
                    handleCloseModal();
                } else {
                    console.error('Failed to update URL:', response.statusText);
                }
            } catch (error) {
                console.error('Error updating URL:', error);
            }
        }
    };

    const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (selectedRecord) {
            setSelectedRecord({ ...selectedRecord, state: Number(e.target.value) });
        }
    };

    const resetForm = () => {
        setNewUrl('');
        setNewUrlState(UrlState.Unverified);
        setModalError('');
    };

    const handleCloseInsertModal = () => {
        resetForm();
        setShowInsertModal(false);
    };

    const handleInsertUrl = async () => {
        setInserting(true);
        try {
            debugger
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/links/create-link`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({ link: newUrl, state: newUrlState })
            });
            if (response.ok) {
                const result = await response.json();
                setRecords(prevRecords => [...prevRecords, result]);
                setResponseMessage('URL inserted successfully.');
                resetForm(); // Clear form after successful insert
            } else {
                setModalError('Failed to insert URL.');
            }
        } catch (error) {
            setModalError('Error inserting URL.');
        } finally {
            setInserting(false);
            setShowInsertModal(false);
            setShowResponseModal(true);
        }
    };

    const handleClearSearch = () => {
        setSearchValue('');
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, state: UrlState) => {
        setSelectedStates(prevStates => {
            const newStates = e.target.checked
                ? [...prevStates, state]
                : prevStates.filter(s => s !== state);
    
            setFilterActive(newStates.length > 0); // Activate filter if there are any selected states
    
            return newStates;
        });
    };
    

    const handleClearAllFilters = () => {
        setSearchValue('');
        setSelectedStates([]);
        setFromDate(null);
        setToDate(null);
        setFilterActive(false);
        setShowFilterModal(false);
    };

    const handleApplyFilter = () => {
        setFilterActive(selectedStates.length > 0);
        setShowFilterModal(false);
    };

    const filteredRecords = records.filter(record => {
        const matchesState = selectedStates.length === 0 || selectedStates.includes(record.state);
        const matchesDate =
            (!fromDate || new Date(record.createdAt) >= fromDate) &&
            (!toDate || new Date(record.createdAt) <= toDate);

        return matchesState && matchesDate;
    });

    const sortedRecords = filteredRecords.sort((a, b) => b.createdAt.localeCompare(a.createdAt));

    return (
        <>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <HashLoader color="#007bff" size={60} />
                </div>
            ) : (
                <Container className="mt-4">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <InputGroup className="w-50">
                            <FormControl
                                type="text"
                                placeholder="Search by URL"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                            {searchValue && (
                                <InputGroup.Text onClick={handleClearSearch} style={{ cursor: 'pointer' }}>
                                    <AiOutlineClose />
                                </InputGroup.Text>
                            )}
                        </InputGroup>
                        
                        <div className="d-flex align-items-center">
                            <Button
                                variant='primary'
                                onClick={() => setShowInsertModal(true)}
                                style={{ marginLeft: '10px', marginRight: '10px' }}
                            >
                                Insert New URL
                            </Button>
                            <Button
                                variant={filterActive ? 'warning' : 'light'}
                                onClick={() => setShowFilterModal(true)}
                                style={{ marginLeft: '10px', marginRight:'10px' }}
                                title="Filter"
                            >
                                <FaFilter />
                            </Button>
                            {/* <Button
                                variant='danger'
                                onClick={handleClearAllFilters}
                                style={{ marginLeft: '10px' }}
                                disabled={!filterActive}
                            >
                                Clear Filter
                            </Button> */}
                        </div>
                    </div>

                    <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
                        <Table striped bordered hover responsive>
                            <thead style={{ position: 'sticky', top: '0', backgroundColor: '#fff', zIndex: 100 }}>
                                <tr>
                                    <th>ID</th>
                                    <th>URL</th>
                                    <th>State</th>
                                    <th>Entry Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {sortedRecords
                    .filter(record => record.url.toLowerCase().includes(searchValue.toLowerCase())) // Filter based on search value
                    .map(record => (
                                    <tr key={record.id}>
                                        <td>{record.id}</td>
                                        <td>{record.url}</td>
                                        <td>{UrlState[record.state]}</td>
                                        <td>{new Date(record.createdAt).toLocaleDateString()}</td>
                                        <td>
                                            <Button variant="info" onClick={() => handleViewDetails(record)}>View Details</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    {showModal && selectedRecord && (
                <Modal show={showModal} onHide={handleCloseModal} centered backdrop="static"   keyboard={false}>
                    <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>
                        <Modal.Title>{editing ? 'Edit Record' : 'Record Details'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <dl>
                            <dt>ID:</dt>
                            <dd>{selectedRecord.id}</dd>
                            <dt>URL:</dt>
                            <dd>{selectedRecord.url}</dd>
                            <dt>State:</dt>
                            <dd>
                                {editing ? (
                                    userType !== 2 ? (
                                        <Form.Control
                                            as="select"
                                            value={selectedRecord.state}
                                            onChange={handleStateChange}
                                        >
                                            <option value={UrlState.Unverified}>Unverified</option>
                                            <option value={UrlState.Safe}>Safe</option>
                                            <option value={UrlState.Unsafe}>Unsafe</option>
                                            <option value={UrlState.Suspicious}>Suspicious</option>
                                        </Form.Control>
                                    ) : (
                                        UrlState[selectedRecord.state]
                                    )
                                ) : (
                                    UrlState[selectedRecord.state]
                                )}
                            </dd>
                            <dt>Entry Date:</dt>
                            <dd>{new Date(selectedRecord.createdAt).toLocaleString()}</dd>
                        </dl>
                    </Modal.Body>
                    <Modal.Footer>
                        {editing && (
                            <>
                                <Button variant="secondary" onClick={handleCloseModal}>
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={handleSaveEdit}>
                                    Save Changes
                                </Button>
                            </>
                        )}
                        {!editing && userType !== 2 && (
                            <Button variant="secondary" onClick={handleEditRecord}>
                                Edit
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
            )}

                    {showInsertModal && (
                        <Modal show={showInsertModal} onHide={handleCloseInsertModal} centered backdrop="static"   keyboard={false}>
                            <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>
                                <Modal.Title>Insert New URL</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {modalError && <Alert variant="danger">{modalError}</Alert>}
                                {responseMessage && <Alert variant="success">{responseMessage}</Alert>}
                                <Form>
                                    <Form.Group controlId="formNewUrl">
                                        <Form.Label>URL</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter URL"
                                            value={newUrl}
                                            onChange={(e) => setNewUrl(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formNewUrlState">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={newUrlState}
                                            onChange={(e) => setNewUrlState(Number(e.target.value))}
                                        >
                                            <option value={UrlState.Unverified}>Unverified</option>
                                            <option value={UrlState.Safe}>Safe</option>
                                            <option value={UrlState.Unsafe}>Unsafe</option>
                                            <option value={UrlState.Suspicious}>Suspicious</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseInsertModal}>Close</Button>
                                <Button variant="primary" onClick={handleInsertUrl} disabled={inserting}>
                                    {inserting ? <Spinner animation="border" size="sm" /> : 'Insert'}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}

                    {showResponseModal && (
                        <Modal show={showResponseModal} onHide={() => setShowResponseModal(false)} centered backdrop="static"   keyboard={false} size='sm'>
                            <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}> 
                                <Modal.Title>Response</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {responseMessage}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowResponseModal(false)}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    )}

                    {showFilterModal && (
                        <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)} centered backdrop="static"   keyboard={false}>
                            <Modal.Header closeButton style={{ backgroundColor: '#007bff', color: '#fff' }}>
                                <Modal.Title>Filter</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group controlId="formStateFilter">
                                        <Form.Label>State</Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            label="Unverified"
                                            checked={selectedStates.includes(UrlState.Unverified)}
                                            onChange={(e) => handleCheckboxChange(e, UrlState.Unverified)}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Safe"
                                            checked={selectedStates.includes(UrlState.Safe)}
                                            onChange={(e) => handleCheckboxChange(e, UrlState.Safe)}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Unsafe"
                                            checked={selectedStates.includes(UrlState.Unsafe)}
                                            onChange={(e) => handleCheckboxChange(e, UrlState.Unsafe)}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Suspicious"
                                            checked={selectedStates.includes(UrlState.Suspicious)}
                                            onChange={(e) => handleCheckboxChange(e, UrlState.Suspicious)}
                                        />
                                    </Form.Group>
                                    
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClearAllFilters}>
                                    Clear Filter
                                </Button>
                               
                                <Button variant="primary" onClick={handleApplyFilter}>
                                    Apply
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}
                </Container>
            )}
        </>
    );
}
