import React, { useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Image, Navbar, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { MDBIcon } from 'mdb-react-ui-kit';
import "./Navbar.css";
import PropTypes from 'prop-types';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { allVariables } from '../Constants/VariableConstants';
import { ERole } from '../Constants/Enums.ts';

const userType = localStorage.getItem('userType');

export default function Sidebar({ onScreenChange }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState(allVariables.dashboardName);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [isBusinessMode, setIsBusinessMode] = useState(
        JSON.parse(localStorage.getItem('isBusinessMode')) || false // Default to Consumer if not set
    );

    // Define Consumer and Business specific sidebar options
    const businessSideBars = [
        allVariables.dashboardName,
        allVariables.CompanyName,
        allVariables.AgentsName,
        allVariables.CommunityName,
        allVariables.TicketManagementName,
        allVariables.ManageUrlName,
        allVariables.Logout,
    ];

    const consumerSideBars = [
        allVariables.dashboardName,
        allVariables.userName,
        allVariables.AgentsName,
        allVariables.ManageUrlName,
        allVariables.TicketManagementName,
        allVariables.PriceManagement,
        // allVariables.CommunityName,
        allVariables.Chat,
        allVariables.Reports,
        allVariables.Logout,
    ];

    // Update active button based on the URL path
    useEffect(() => {
        switch (location.pathname) {
            case "/dashboard":
            case "/b_dashboard":
                setActiveButton(allVariables.dashboardName);
                break;
            case "/users":
            case "/company":
                setActiveButton(allVariables.userName);
                break;
            case "/agents":
            case "/b_agents":
                setActiveButton(allVariables.AgentsName);
                break;
            case "/community":
            case "/b_community":
                setActiveButton(allVariables.CommunityName);
                break;
            case "/url":
            case "/b_url":
                setActiveButton(allVariables.ManageUrlName);
                break;
            case "/tickets":
            case "/b_tickets":
                setActiveButton(allVariables.TicketManagementName);
                break;
            case "/price":
            case "/b_price":
                setActiveButton(allVariables.PriceManagement);
                break;
            case "/report":
            case "/b_report":
                setActiveButton(allVariables.Reports);
                break;
            case "/chat":
            case "/b_chat":
                setActiveButton(allVariables.Chat);
                break;
            case "/Logout":
                setActiveButton(allVariables.Logout);
                break;
            default:
                setActiveButton(allVariables.dashboardName);
                break;
        }
    }, [location.pathname]);

    const userBtnClick = (btnName) => {
        if (btnName === allVariables.Logout) {
            setShowLogoutModal(true); // Open the logout confirmation modal
        } else {
            onScreenChange(btnName);
            markComponentActive(btnName);
        }
    };

    const markComponentActive = (name) => {
        setActiveButton(name);
    };

    const handleLogout = () => {
        // Clear local storage
        localStorage.removeItem('authToken');
        localStorage.removeItem('userType');
        localStorage.removeItem('userName');
        localStorage.removeItem('email');
        localStorage.removeItem('userId');
        localStorage.removeItem('isBusinessMode'); // Clear business mode setting

        // Close the logout modal
        setShowLogoutModal(false);

        // Redirect to login page
        navigate('/login');
        window.location.reload(); // Force a full page reload
    };

    const handleCloseModal = () => setShowLogoutModal(false);

    const handleToggleMode = () => {
        setIsBusinessMode((prevMode) => {
            const newMode = !prevMode;

            // Save the new mode in localStorage
            localStorage.setItem('isBusinessMode', JSON.stringify(newMode));

            // Programmatically navigate to the appropriate dashboard based on the mode
            if (newMode) {
                navigate('/b_dashboard'); // Navigate to Business Dashboard
            } else {
                navigate('/dashboard'); // Navigate to Consumer Dashboard
            }

            return newMode;
        });
    };

    // Create dynamic URLs based on whether the user is in Consumer or Business mode
    const getLinkForMode = (ele) => {
        if (isBusinessMode) {
            return (
                ele === allVariables.dashboardName ? "/b_dashboard" :
                    ele === allVariables.CompanyName ? "/company" :
                        ele === allVariables.AgentsName ? "/agents" :
                            ele === allVariables.CommunityName ? "/b_community" :
                                ele === allVariables.ManageUrlName ? "/b_url" :
                                    ele === allVariables.TicketManagementName ? "/b_tickets" :
                                        ele === allVariables.PriceManagement ? "/b_price" :
                                            ele === allVariables.Reports ? "/b_report" :
                                                ele === allVariables.Chat ? "/b_chat" :
                                                    ''
            );
        } else {
            return (
                ele === allVariables.dashboardName ? "/dashboard" :
                    ele === allVariables.userName ? "/users" :
                        ele === allVariables.AgentsName ? "/agents" :
                            ele === allVariables.CommunityName ? "/community" :
                                ele === allVariables.ManageUrlName ? "/url" :
                                    ele === allVariables.TicketManagementName ? "/tickets" :
                                        ele === allVariables.PriceManagement ? "/price" :
                                            ele === allVariables.Reports ? "/report" :
                                                ele === allVariables.Chat ? "/chat" :
                                                    ''
            );
        }
    };

    const currentSideBars = isBusinessMode ? businessSideBars : consumerSideBars; // Choose sidebar options based on the toggle

    return (
        <div className='w-100'>
            <Card className='rounded-0 rounded-end'
                style={{ 
                    height: '100vh', // Set the height to 80% of the viewport
                    backgroundImage: isBusinessMode 
                        ? 'linear-gradient(to bottom right, #4caf50, #2e7d32)'  // Green for Business mode
                        : 'linear-gradient(to bottom right, #89cfff, #2a4659)'  // Original color for Consumer mode
                }}>
                <Card.Header>
                    <Image className='w-75' src="../../Icons/Phishroko_logo_dashboard.png" fluid />
                </Card.Header>
                <div style={{ padding: '0', margin: '0' }}>
                    {currentSideBars.map((ele, key) => {
                        if (userType == ERole.Agent && ele === allVariables.AgentsName) return null;
                        if (userType == 2 && ele === allVariables.Reports) return null;  // Exclude Reports section for userType 2
                        if (userType == 2 && ele === allVariables.PriceManagement) return null;  // Exclude Agents section for userType 2
                        
                        if (userType == 2 && ele === allVariables.AgentsName) return null;  // Exclude Agents section for userType 2
                        return (
                            <Navbar className='mt-1 mb-1 pb-1 pt-1' key={key} expand="lg">
                                {/* Only use Link for non-logout buttons */}
                                {ele === allVariables.Logout ? (
                                    <Button className='w-100 h-100 d-flex justify-content-start align-items-center' variant={activeButton === ele ? "info" : "primary"}
                                        onClick={() => userBtnClick(ele)}
                                        style={{ fontSize: activeButton === ele ? '16px' : '14px', padding: '0.5rem' }}>
                                        <MDBIcon fas icon="sign-out-alt" size='1x' />
                                        <span className='ms-2 me-auto fs-6'>{ele}</span>
                                        {activeButton === ele && <span className='dot-indicator bg-white' />}
                                    </Button>
                                ) : (
                                    <Link className='w-100 h-100 d-flex justify-content-start align-items-center' to={getLinkForMode(ele)}>
                                        <Button className='w-100 h-100 d-flex justify-content-start align-items-center' variant={activeButton === ele ? "info" : "primary"}
                                            onClick={() => userBtnClick(ele)}
                                            style={{ fontSize: activeButton === ele ? '16px' : '14px', padding: '0.5rem' }}>
                                            <MDBIcon fas icon={
                                                ele === allVariables.dashboardName ? "tachometer-alt" :
                                                    ele === allVariables.userName ? "users" :
                                                    ele === allVariables.CompanyName ? "building" :
                                                        ele === allVariables.AgentsName ? "headset" :
                                                            ele === allVariables.CommunityName ? "bullhorn" :
                                                                ele === allVariables.ManageUrlName ? "link" :
                                                                    ele === allVariables.PriceManagement ? "dollar-sign" :
                                                                        ele === allVariables.Reports ? "database" :
                                                                            ele === allVariables.Chat ? "message" :
                                                                                ele === allVariables.TicketManagementName ? "ticket" : ''
                                            } size='1x' />
                                            <span className='ms-2 me-auto fs-6'>{ele}</span>
                                            {activeButton === ele && <span className='dot-indicator bg-white' />}
                                        </Button>
                                    </Link>
                                )}
                            </Navbar>
                        );
                    })}
                </div>

                {/* Toggle Switch at the bottom for Consumer/Business */}
                <div className="mt-auto p-2">
    <Form>
        <Form.Group
            as={Row}
            className="align-items-center justify-content-center text-center"
            style={{ flexWrap: 'nowrap' }}
        >
            {/* Consumer Label */}
            <Col xs="auto" className="px-2">
                <Form.Label className="mb-0 small-label">Consumer</Form.Label>
            </Col>
            {/* Toggle Switch */}
            <Col xs="auto" className="px-2">
                <Form.Check
                    type="switch"
                    id="mode-switch"
                    // checked={isBusinessMode}
                    // onChange={handleToggleMode}
                />
            </Col>
            {/* Business Label */}
            <Col xs="auto" className="px-2">
                <Form.Label className="mb-0 small-label">Business</Form.Label>
            </Col>
        </Form.Group>
    </Form>
</div>


            </Card>

            {/* Logout Confirmation Modal */}
            <Modal show={showLogoutModal} onHide={handleCloseModal} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to logout?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleLogout}>
                        Logout
                    </Button>
                </Modal.Footer>
            </Modal>

            <Outlet />
        </div>
    );
}

Sidebar.propTypes = { onScreenChange: PropTypes.func.isRequired };
