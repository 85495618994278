import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button, Alert, Modal, Table } from 'react-bootstrap';

interface Agent {
  id: number;
  firstName: string;
  lastName: string;
  role: number;
}

interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  contact: string;
  subscriptionType: number;
}

interface CreateTicketForm {
  title: string;
  description: string;
  entityType: number;
  attachment: string;
  assignTo: number;
  createFor: number;
}

interface AssignedUser {
  userId: number;
  name: string;
}

const initialUserFormState: CreateTicketForm = {
  title: '',
  description: '',
  entityType: 0,
  attachment: '',
  assignTo: 0,
  createFor: 0,
};

const CreateTicket: React.FC<{ setData: any; onClose: () => void; }> = ({ setData, onClose }) => {
  const [ticket, setTicket] = useState<CreateTicketForm>(initialUserFormState);
  const [agentsList, setAgentsList] = useState<Agent[]>([]);
  const [usersList, setUsersList] = useState<IUser[]>([]);
  const [filteredAgents, setFilteredAgents] = useState<Agent[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [assignedAgent, setAssignedAgent] = useState<AssignedUser | null>(null);
  const [assignedUser, setAssignedUser] = useState<AssignedUser | null>(null);
  const [agentsLoaded, setAgentsLoadedFlag] = useState(false);
  const [usersLoaded, setUsersLoadedFlag] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [agentSearchTerm, setAgentSearchTerm] = useState('');
  const [showUserModal, setShowUserModal] = useState(false);
  const [showAgentModal, setShowAgentModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const userType = Number(localStorage.getItem('userType'));
  const loggedInUserId = Number(localStorage.getItem('userId'));

  useEffect(() => {
    const fetchUsersAndAgents = async () => {
      try {
        const page = 1;
        const pageSize = 100;

        const agentsUrl = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/User/get-all-agents`);
        const usersUrl = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/user/get-all-users`);

        agentsUrl.searchParams.append('page', page.toString());
        agentsUrl.searchParams.append('pageSize', pageSize.toString());
        usersUrl.searchParams.append('page', page.toString());
        usersUrl.searchParams.append('pageSize', pageSize.toString());

        const [agentsResponse, usersResponse] = await Promise.all([
          fetch(agentsUrl.toString(), {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
          }),
          fetch(usersUrl.toString(), {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
          }),
        ]);

        if (agentsResponse.ok) {
          const agentsResult = await agentsResponse.json();
          setAgentsList(agentsResult);
          setFilteredAgents(agentsResult.filter(agent => agent.role === 2));
          setAgentsLoadedFlag(true);
        } else {
          console.error('Failed to fetch agents:', agentsResponse.statusText);
        }

        if (usersResponse.ok) {
          const usersResult = await usersResponse.json();
          setUsersList(usersResult);
          setFilteredUsers(usersResult);
          setUsersLoadedFlag(true);
        } else {
          console.error('Failed to fetch users:', usersResponse.statusText);
        }
      } catch (error) {
        console.error('Error fetching users or agents:', error);
      }
    };

    fetchUsersAndAgents();
  }, []);
  const resetForm = () => {
    setTicket(initialUserFormState); // Reset form fields to their initial state
    setAssignedAgent(null);
    setAssignedUser(null);
    setUserSearchTerm('');
    setAgentSearchTerm('');
  };
  
  const handleDropdownChange = (name: string, selectedItem: AssignedUser | null) => {
    if (name === 'assignUser') {
      setAssignedUser(selectedItem);
      setTicket(prevState => ({
        ...prevState,
        createFor: selectedItem ? selectedItem.userId : 0,
      }));
    } else if (name === 'assignAgent') {
      setAssignedAgent(selectedItem);
      setTicket(prevState => ({
        ...prevState,
        assignTo: selectedItem ? selectedItem.userId : 0,
      }));
    }
  };

  const handleUserSelect = (user: IUser) => {
    handleDropdownChange('assignUser', { userId: user.id, name: `${user.firstName} ${user.lastName}` });
    setShowUserModal(false);
  };

  const handleAgentSelect = (agent: Agent) => {
    handleDropdownChange('assignAgent', { userId: agent.id, name: `${agent.firstName} ${agent.lastName}` });
    setShowAgentModal(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!ticket.title || !ticket.description || (userType !== 2 && ticket.assignTo === 0) || ticket.createFor === 0) {
      setErrorMessage('All fields are required.');
      return;
    }
    setErrorMessage(null);
    setShowConfirmation(true);
  };

  const handleConfirm = async () => {
    setShowConfirmation(false);
    try {
      const payload = {
        title: ticket.title,
        description: ticket.description,
        entityType: ticket.entityType,
        attachment: ticket.attachment,
        assignTo: userType === 2 ? loggedInUserId : ticket.assignTo,
        createFor: ticket.createFor,
      };

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/ticket/create-ticket`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (response.ok) {
        setSuccessMessage('Ticket created successfully.');
        
        const data = {
          id: result.id,
          status: result.status,
          assignedBy: result.assignedBy,
          assignedTo: result.assignedTo,
          assignedByUser:"",
          assignedToUser:"",
          createdFor: result.createdFor,
          createdForUser:"",
          attachment:null,
          desc: result.desc,
          resolution:result.resolution,
          entityType: 4,
          createdAt:result.createdAt,
          updatedAt:result.updatedAt,
          title: ticket.title.trim(),
          // desc: ticket.descrip.trim(),
        };

        

        setData(prevState => [...prevState, data]);
        resetForm(); // Clear form data
      } else {
        setErrorMessage('Failed to create ticket.');
        console.error('Ticket creation failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating ticket:', error);
    }
  };

  const handleCloseSuccess = () => {
    setSuccessMessage(null);
  };

  const getSubscriptionStatus = (subscriptiontype: number) => {
    switch (subscriptiontype) {
      case 0:
        return 'Not subscribed';
      case 1:
        return 'Subscribed';
      default:
        return 'Not subscribed';
    }
  };
  const Roles = {
    ADMIN: 0,
    AGENT: 2,
    USER: 3,
    Specialist: 1,
  };

  const getRoleName = (role: number) => {
    switch (role) {
      case Roles.ADMIN:
        return 'Admin';
      case Roles.AGENT:
        return 'Agent';
      case Roles.USER:
        return 'User';
      case Roles.Specialist:
        return 'Specialist';
      default:
        return 'Unknown';
    }
  };
  return (
    <>
      <Form id="create-ticket-form" onSubmit={handleSubmit}>
        <Form.Group controlId="formTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={ticket.title}
            onChange={(e) => setTicket({ ...ticket, title: e.target.value })}
            required
            className="mb-4"
          />
        </Form.Group>

        <Form.Group controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            value={ticket.description}
            onChange={(e) => setTicket({ ...ticket, description: e.target.value })}
            required
            className="mb-4"
          />
        </Form.Group>

        <Row>
        <Row>
  <Col sm={6}>
    <Form.Group className="d-flex flex-column h-100">
      <Form.Label>Create For: </Form.Label>
      <Button variant="primary" onClick={() => setShowUserModal(true)} block className="mt-2">
        {assignedUser?.name || 'Select User'}
      </Button>
    </Form.Group>
  </Col>

  <Col sm={6}>
    {userType !== 2 && (
      <Form.Group className="d-flex flex-column h-100">
        <Form.Label>Assign To: </Form.Label>
        <Button variant="primary" onClick={() => setShowAgentModal(true)} disabled={userType === 2} className="mt-2">
          {assignedAgent?.name || 'Select Agent'}
        </Button>
      </Form.Group>
    )}
  </Col>
</Row>

</Row>


        {errorMessage && <Alert variant="danger" className="mt-4">{errorMessage}</Alert>}

        <Button variant="primary" type="submit" className="mt-4" disabled={!agentsLoaded || !usersLoaded}>
          Create Ticket
        </Button>
      </Form>

      {/* User List Modal */}
      <Modal show={showUserModal} onHide={() => setShowUserModal(false)} size='xl' centered backdrop="static"   keyboard={false}>
      <Modal.Header closeButton style={{ backgroundColor: '#49c5f2', color: '#fff' }}>
          <Modal.Title>Select User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Search by name, email, or contact..."
            value={userSearchTerm}
            onChange={(e) => setUserSearchTerm(e.target.value)}
            className="mb-4"
          />
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Table responsive style={{ minWidth: '100%' }}>
              <thead>
                <tr>
                  <th style={{ width: '25%' }}>Name</th>
                  <th style={{ width: '25%' }}>Email</th>
                  <th style={{ width: '20%' }}>Subscription Status</th>
                  <th style={{ width: '20%' }}>Contact</th>
                  <th style={{ width: '10%' }}>Select</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers
                  .filter(user => 
                    user.firstName.toLowerCase().includes(userSearchTerm.toLowerCase()) ||
                    user.lastName.toLowerCase().includes(userSearchTerm.toLowerCase()) ||
                    user.email.toLowerCase().includes(userSearchTerm.toLowerCase()) ||
                    user.contact.toLowerCase().includes(userSearchTerm.toLowerCase())
                  )
                  .map(user => (
                    <tr key={user.id}>
                      <td>{user.firstName} {user.lastName}</td>
                      <td>{user.email}</td>
                      <td>{getSubscriptionStatus(user.subscriptionType)}</td>
                      <td>{user.contact}</td>
                      <td>
                        <Button 
                          variant="outline-primary" 
                          onClick={() => handleUserSelect(user)}
                          disabled={user.subscriptionType === 0} // Disable if not subscribed
                          style={{ whiteSpace: 'nowrap' }} // Ensure button text doesn't wrap
                        >
                          Select
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUserModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Agent List Modal */}
      <Modal show={showAgentModal} onHide={() => setShowAgentModal(false)} size='md' centered backdrop="static"   keyboard={false}>
      <Modal.Header closeButton style={{ backgroundColor: '#49c5f2', color: '#fff' }}>
          <Modal.Title>Select Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Search by name..."
            value={agentSearchTerm}
            onChange={(e) => setAgentSearchTerm(e.target.value)}
            className="mb-4"
          />
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Table responsive style={{ minWidth: '100%' }}>
              <thead>
                <tr>
                  <th style={{ width: '30%' }}>Name</th>
                  <th style={{width:'30%'}}>Role</th>
                  <th style={{ width: '40%' }}>Select</th>
                </tr>
              </thead>
              <tbody>
                {filteredAgents
                  .filter(agent => 
                    `${agent.firstName} ${agent.lastName}`.toLowerCase().includes(agentSearchTerm.toLowerCase())
                  )
                  .map(agent => (
                    <tr key={agent.id}>
                      <td>{agent.firstName} {agent.lastName}</td>
                      <td>{getRoleName(agent.role)}</td>
                      <td>
                        <Button 
                          variant="outline-primary" 
                          onClick={() => handleAgentSelect(agent)}
                          style={{ whiteSpace: 'nowrap' }} // Ensure button text doesn't wrap
                        >
                          Select
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAgentModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)} centered backdrop="static"   keyboard={false}> 
        <Modal.Header closeButton style={{ backgroundColor: '#49c5f2', color: '#fff' }}>
          <Modal.Title>Confirm Ticket Creation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to create this ticket?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Success Message Modal */}
      <Modal show={!!successMessage} onHide={handleCloseSuccess} centered backdrop="static"   keyboard={false}>
        <Modal.Header closeButton style={{ backgroundColor: '#49c5f2', color: '#fff' }}>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>{successMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccess}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateTicket;
