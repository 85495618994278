// src/components/DashboardCards.js
import React from 'react';
import './dashboard.css';

const cardsData = [
    { id: 1, value: '150', title: 'New Orders', color: '#00c0ef', icon: 'fa-shopping-cart' },
    { id: 2, value: '53%', title: 'Bounce Rate', color: '#00a65a', icon: 'fa-bar-chart' },
    { id: 3, value: '44', title: 'User Registrations', color: '#f39c12', icon: 'fa-user-plus' },
    { id: 4, value: '65', title: 'Unique Visitors', color: '#dd4b39', icon: 'fa-pie-chart' },
];

const dashboard = () => {
    return (
        <div className="dashboard-cards">
            {cardsData.map(card => (
                <div key={card.id} className="card" style={{ backgroundColor: card.color }}>
                    <div className="card-body">
                        <div className="card-value">{card.value}</div>
                        <div className="card-title">{card.title}</div>
                    </div>
                    <div className="card-footer">
                        <a href="#" className="card-link">More info <i className="fa fa-arrow-circle-right"></i></a>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default dashboard;
