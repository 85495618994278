import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import Sidebar from './Sidebar.js';
import AppNavbar from './Navbar.js';
import UrlManagement from './url/UrlManagement.tsx';
import TicketManagement from './ticket/TicketManagement.tsx';
import UserManagement from './user/UserManagement.tsx';
import AgentManagement from './agent/Agentmanagement.tsx';
import SubscriptionPage from './subscription/Subscription.tsx';
import PriceManagement from './price/priceManagement.tsx';
import UserView from './report/reportManagement.tsx';
import Dashboard from './dashboard.js';
import Community from './community/Community.js';
import LogoutPage from './account/LogoutPage.tsx';
import { Row, Col, ToastContainer, Toast } from "react-bootstrap";
import ChatMessenger from './chat/ChatMessenger.tsx';
import * as signalR from "@microsoft/signalr";
import { allStringsArray } from '../Constants/VariableConstants.js';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit';

const loggedInUserId = Number(localStorage.getItem("userId"));

interface NewSessionNotification {
  userId: string;
  sessionId: string;
  userName: string;
  joined?: boolean; // Add this flag to track joined status
}
export default function Home() {
  const [sessions, setSessions] = useState<NewSessionNotification[]>([]);
  const [selectedSession, setSelectedSession] = useState<NewSessionNotification | null>(null);
  const [screen, setScreen] = useState('Dashboard');
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
  const [notifications, setNotifications] = useState<NewSessionNotification[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const[activeChat, setActiveChat] = useState<number>();
  
  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl("https://phishroko-api-dev.azurewebsites.net/chathub")
      .withAutomaticReconnect()
      .build();

    newConnection.start()
      .then(() => {
        console.log("SignalR Connected.");
        newConnection.on("NewSessionStarted", (data) => {
          setNotifications((prev) => [...prev, data]);
          debugger
          setShowPopup(true);
        });

        newConnection.on('SessionEnded', (endedSessionId) => {
          console.log("Session Ended");
          // if (endedSessionId === sessionId) {
          //   alert('The chat session has ended');
          //   sessionId = null; // Clear the session ID since it's ended
          // }
        });
      })
      .catch(err => console.error("Connection failed: ", err));

    setConnection(newConnection);
  }, []);

  const handleJoinSession = async (session: NewSessionNotification) => {
    try {
      if (connection) {
        await connection.invoke(
          "JoinSession",
          Number.parseInt(session.sessionId),
          loggedInUserId
        );
        debugger
        setSelectedSession(session);
        setShowPopup(false);
        var newSession : NewSessionNotification = {
          userId: session.userId,
          sessionId: session.sessionId,
          userName: session.userName
        };

        setSessions((prevSessions) => [...prevSessions, newSession]);
        setActiveChat(Number.parseInt(session.sessionId));
        setNotifications((prevNotifications) =>
          prevNotifications.filter((n) => n.sessionId !== session.sessionId)
        );
      }
    } catch (error) {
      console.error("Error joining session:", error);
    }
  };


  const handleScreenChange = (screenName) => setScreen(screenName);

  return (
    <div className='Container' style={{ height: '100%' }}>
      <Row>
        <Col md={3}>
          <Sidebar onScreenChange={handleScreenChange} sideBars={allStringsArray} />
        </Col>
        <Col md={9}>
          <Row>
            <AppNavbar title={screen} />
          </Row>
          <Row className='mh-100'>
            <Routes>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="users" element={<UserManagement />} />
              <Route path="url" element={<UrlManagement />} />
              <Route path="tickets" element={<TicketManagement />} />
              <Route path="agents" element={<AgentManagement />} />
              <Route path="price" element={<PriceManagement />} />
              <Route path="report" element={<UserView />} />
              <Route path="subscription" element={<SubscriptionPage />} />
              <Route path="community" element={<Community />} />
              <Route path="chat" element={<ChatMessenger connection={connection} 
                sessions={sessions} setSessions={setSessions} 
                setSelectedSession={setSelectedSession} selectedSession={selectedSession}
                setActiveChat={ setActiveChat}
                activeChat={activeChat}/>} />
              <Route path="Logout" element={<LogoutPage />} />
              <Route path="*" element={<Navigate to="dashboard" />} />
            </Routes>
                {/* Toast Notifications */}
       <ToastContainer position="bottom-end" className="p-3">
        {notifications.map((notification, index) => (
          <Toast
            key={index}
            onClose={() =>
              setNotifications((prev) => prev.filter((_, i) => i !== index))
            }
            delay={25000}
            autohide
          >
            <Toast.Header>
              <strong className="me-auto">New Chat Session</strong>
            </Toast.Header>
            <Toast.Body>
              {`User ${notification.userName} started a session.`}
              <MDBBtn
                color="primary"
                onClick={() => handleJoinSession(notification)}
              >
                Join Chat
              </MDBBtn>
            </Toast.Body>
          </Toast>
        ))}
        </ToastContainer>
          </Row>
         {/* Popup for New Session*/}
      

        </Col>
      </Row>
    </div>
  );
}
