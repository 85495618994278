const dashboardName = 'Dashboard';
const userName = 'Users';
const ManageUrlName = 'Manage URL';
const AgentsName = 'Agents';
const TicketManagementName = 'Incident Management';
const CommunityName = 'Community';
const PriceManagement = 'Subscription Plan';
const Reports = 'Reports';
const Chat = 'Chat';
const CompanyName = 'Company';

const Logout = "Logout";
export const authToken = 'authToken';

const allVariables = {dashboardName, userName, CompanyName,ManageUrlName, AgentsName, TicketManagementName, CommunityName,PriceManagement,Chat, Reports,Logout}
const allStringsArray = Object.values(allVariables).map(ele => {return ele});
export {allVariables,allStringsArray};