const Roles = {
    ADMIN: 1,
    AGENT: 2,
    USER: 3,
    Specialist:4,
};
export enum SubscriptionType{
    Free,
    Premium
}

 enum ERole {
    Admin,
    Agent,
    User,
    Specialist,
  }
const FilterTypes = {
    ASCENDING : 0,
    DESCENDING : 1,
    DROPDOWN : 2,
    CHECKBOX: 3
}

const ButtonType = {
    NONE : 0,
    SORTING : 1,
    DROPDOWN : 2,
    CHECKBOX : 3
}
 enum TicketStatus{
    New,
    Active,
    Resolved,
    Closed
    
}

const Status = {
    Active:0,
    Inactive:1
}

const Online  = {
    Online : 0,
    Offline : 1
}

enum UrlState{
    Unverified,
    Safe,
    Unsafe,
    Suspicious
}

const Sorting = {
    Ascending:0,
    Decending:1
}
enum SessionStatus
{
    WaitingForAgent,
    InProgress,
    Completed
}
export {Roles, FilterTypes, ButtonType, Status, Online, TicketStatus, UrlState, Sorting,ERole,SessionStatus}


